export const saveArrayToLocalStorage = (array) => {
  localStorage.setItem("CurrentTabPage", JSON.stringify(array));
};

const getArrayFromLocalStorage = () => {
  const data = localStorage.getItem("CurrentTabPage");
  return data ? JSON.parse(data) : [];
};

export const getArrayItemFromLocalStorage = (ind) => {
  const data = localStorage.getItem("CurrentTabPage");
  if (!data) return 1;

  const array = JSON.parse(data);
  return ind >= 0 && ind < array.length ? array[ind] : 1;
};

export const updateArrayItemInLocalStorage = (index, newValue) => {
  const array = getArrayFromLocalStorage();
  if (index >= 0 && index < array.length) {
    array[index] = newValue;
  } else {
    for (let i = array.length; i <= index; i++) {
      array[i] = i === index ? newValue : 1;
    }
  }
  saveArrayToLocalStorage(array);
};
