import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter, customIconInGridCell, getDateFormatted } from "../../MasterComponents/CommonFunctionalities"; 
import { showAlertMessage, checkIfArrayIsEmpty } from '../../MasterComponents/CommonFunctionalities';
import { getArrayItemFromLocalStorage } from "../../UI Helpers/TabRecorder/TabRecorderFunctions";

const SystemOnlineClassesListings = ({ studentPortal = false }) => {
  const [onlineClassesData, setOnlineClassesData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(getArrayItemFromLocalStorage(10));
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => { getAllSystemOnlineClasses(); }, []);

  const getAllSystemOnlineClasses = async () => { 
    try {
      const apiResponse = await apiCalls.getSystemOnlineClasses();
      if (apiResponse) {
        setOnlineClassesData(apiResponse?.data);
        setTotalRecords(apiResponse?.data?.length);
        setLoading(false);
      }
    } catch (err) {
      setOnlineClassesData([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onTopicClicked = (row, colKey) => {
    if(colKey === 'class_topic' && !studentPortal){
      navigate(`/portal/system-online-classes?classid=${row?.class_id}`);
    }
    if(colKey === 'zoom_live_link' && studentPortal){
      const url = row?.zoom_live_link;
      if (url) {
        window.open(url, '_blank');
      }
    }  
  };

  const onDeleteSystemOnlineClasses = async (rowItems) => {
    if(checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoading(true);

    for (const item of rowItems) {   
      try {
        const apiResponse = await apiCalls.removeSystemOnlineClass(item?.class_id);
        if(apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getAllSystemOnlineClasses();
  };
  
  const gridColumnHeaders = [
    { field: 'class_topic', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Class Topic' },
    { field: 'class_subject', columnName: 'Class Subject' },
    { field: 'session_date_time', cellRenderer: getDateFormatted, customRendererLink: true, columnName: 'Session Date Time' },
    { field: 'zoom_live_link', columnName: 'Zoom Link', cellRenderer: customIconInGridCell, customRendererLink: true }
  ];

  return (
    <div className='listings_view_wrapper'> 
      <h1 className="h1_main">{studentPortal ? 'Online Classes' : 'All System Online Classes'}</h1>

      <CustomDataGrid 
        columns={gridColumnHeaders} 
        data={onlineClassesData} 
        pageSize={pageSize} 
        loadingState={loading}
        hideAllBtns={studentPortal}
        checkboxRequired={!studentPortal}
        pagination={false}
        onAddClick={() => navigate('/portal/system-online-classes')}
        onCellClicked={onTopicClicked}
        onSelectedRowsDelete={onDeleteSystemOnlineClasses}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
        tabID={10}
      />
    </div>
  )
}

export default SystemOnlineClassesListings
