import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter, getDateFormatted } from "../../MasterComponents/CommonFunctionalities"; 
import { showAlertMessage, checkIfArrayIsEmpty } from '../../MasterComponents/CommonFunctionalities';
import { getArrayItemFromLocalStorage } from "../../UI Helpers/TabRecorder/TabRecorderFunctions";

const SystemDaysListings = () => {
  const [examsData, setExamsData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(getArrayItemFromLocalStorage(7));
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => { getAllSystemExams(); }, []);

  const getAllSystemExams = async () => { 
    try {
      const apiResponse = await apiCalls.getSystemDays();
      if (apiResponse) {
        setExamsData(apiResponse?.data);
        setTotalRecords(apiResponse?.data?.length);
        setLoading(false);
      }
    } catch (err) {
      setExamsData([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onTopicClicked = (row, colKey) => {
    if(colKey === 'enum_name'){
      navigate(`/portal/system-days?dayid=${row?.enum_id}`);
    }  
  };

  const onDeleteSystemExams = async (rowItems) => {
    if(checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoading(true);

    for (const item of rowItems) {   
      try {
        const apiResponse = await apiCalls.removeSystemDay(item?.enum_id);
        if(apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getAllSystemExams();
  };

  const gridColumnHeaders = [
    { field: 'enum_name', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Day' },

  ];

  return (
    <div className='listings_view_wrapper'> 
      <h1 className="h1_main">All System Days</h1>

      <CustomDataGrid 
        columns={gridColumnHeaders} 
        data={examsData} 
        pageSize={pageSize} 
        loadingState={loading}
        pagination={false}
        onAddClick={() => navigate('/portal/system-days')}
        onCellClicked={onTopicClicked}
        onSelectedRowsDelete={onDeleteSystemExams}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
        tabID={7}
      />
    </div>
  )
}

export default SystemDaysListings
