import React, { useEffect, useState } from "react";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Settings from "../../CommonAPICalls/Settings";
import { getArrayItemFromLocalStorage } from "../../UI Helpers/TabRecorder/TabRecorderFunctions";

const apiCall = new CommonCalls();

const StudentMarksListing = () => {
    const [loading, setLoading] = useState(false);
    const [saveBtn, setSaveBtn] = useState(false);
    const [saveBtnSearch, setsaveBtnSearch] = useState(false);
    const [userData, setUserData] = useState([]);
    const [updatedUserData,setUpdatedUserData] =useState([])
    const [formInputData, setFormInputData] = useState({});
    const [campusesList, setCampusesList] = useState([]);
    const [pageNumber, setPageNumber] = useState(getArrayItemFromLocalStorage(6));
    const [pageSize, setPageSize] = useState(10);
    const [examsData, setExamsData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [examDetails, setExamDetails] = useState();
    const [allExams, setAllExams] = useState([]);

    const apiCalls = new CommonCalls();

    useEffect(() => {
        const fetchCampuses = async () => {
            try {
                const response = await apiCall.getSystemCampuses();
                if (response && response.data && Array.isArray(response.data)) {
                    setCampusesList(response.data.map(item => ({
                        value: item.campus_id,
                        label: item.campus_name
                    })));
                } else {
                    setCampusesList([]);
                    console.error("No data found for campuses.");
                }
            } catch (error) {
                console.error("Error fetching campuses:", error);
                setCampusesList([]);
            }
        };

        fetchCampuses();
    }, []);

    const inputChangeHandler =  (event, changedObj, changedField) => {
                
        const isNumeric = true
       
        if(parseInt(event.target.value) > parseInt(examDetails[event.target.getAttribute("data-checkValue")]))
        {
            showAlertMessage("Could not exceed the value from total","fail")
            return
        }
        if(isNumeric)
        {
            setUserData((prev) => {
                
                const userDataIndex = prev.findIndex((data) => data.email == changedObj.email);
               
                if (userDataIndex === -1) return prev; 
                const updatedUserData = [...prev];
                updatedUserData[userDataIndex][changedField] = event.target.value;
                return updatedUserData;
            });
    
            setUpdatedUserData((prevUpdated) => {
                const existingIndex = prevUpdated.findIndex((data) => data.email === changedObj.email);
                const newData = { ...changedObj, [changedField]: event.target.value };
        
                if (existingIndex !== -1) {
                    const updatedArray = [...prevUpdated];
                    updatedArray[existingIndex] = newData;
                    return updatedArray;
                } else {
                    return [...prevUpdated, newData];
                }
            });

        }
        else
        {
            showAlertMessage("Please insert a valid Numeric Value","fail")
        }
        
        
    }

    const fetchUsers = async (formRequest=false) => {
        setLoading(true);
        const { student_category, student_campus_id,exam_topic } = formInputData;

        setExamDetails(allExams.find(exam => exam.exam_id == exam_topic))
        
        if ((!student_category || !student_campus_id || !exam_topic) && formRequest) {
            showAlertMessage("Please select all fields.", "fail");
            setLoading(false);
            return;
        }
        setsaveBtnSearch(true)

        try {
            const response = await apiCall.getUserMarksAndAttendenceDetails(
                student_category,
                student_campus_id,
                exam_topic,
                pageNumber,
                pageSize
            );

            if (response && response.data) {
                setUserData(response?.data)
                setTotalRecords(response?.total_records);
            } else {
                setUserData([]);
                if(formRequest)
                {
                    showAlertMessage("Failed to fetch student data", "fail");
                }
            }
        } catch (error) {
            setUserData([]);
            if(formRequest)
            {
                showAlertMessage("Failed to fetch data", "fail");
            }
        } finally {
            setLoading(false);
            setsaveBtnSearch(false)
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [ pageNumber, pageSize]);

    const updateUserMarks = async () => {
        setLoading(true);
        setSaveBtn(true)
       
       const withAttendanceId = updatedUserData.filter(item => item.examination_attendance_id !== null);
       const withoutAttendanceId = updatedUserData.filter(item => item.examination_attendance_id === null);
       const withAttendenceData = withAttendanceId.map((data)=>{return(
        {
            examination_attendance_id:data?.examination_attendance_id,
            user_id:data?.user_id,
            user_marks:data?.user_marks=="" ? 0:data?.user_marks
        }
        )})
        const withoutAttendenceData = withoutAttendanceId.map((data)=>{return(
            {
                exam_id:examDetails.exam_id,
                user_id:data?.user_id,
                user_marks:data?.user_marks=="" ? 0:data?.user_marks
            }
        )})

        try {
            if(withAttendenceData.length > 0)
            {
                const response = await apiCall.updateUserMarksWithAttendence(
                    withAttendenceData
                );
                if (response.status ===202) {
                    showAlertMessage(response?.message, "success");
                    setUpdatedUserData([])
                } else {
                    showAlertMessage(response?.message, "fail");
                }

            }
            if(withoutAttendenceData.length > 0)
            {
                const response = await apiCall.updateUserMarksWithOutAttendence(
                    withoutAttendenceData
                );
                if (response.status ===201) {
                    showAlertMessage(response?.message, "success");
                    setUpdatedUserData([])
                } else {
                    showAlertMessage(response?.message, "fail");
                }

            }
        } catch (error) {
            showAlertMessage("Failed to update user data", "fail");
        } finally {
             setSaveBtn(false)
            setLoading(false);
        }
    };


    useEffect(() => {
        const getAllSystemExams = async () => {
            try {
                const apiResponse = await apiCalls.getExamDetails();
                if (apiResponse && apiResponse.data) {
                    const transformedExams = apiResponse.data.map(exam => ({
                        value: exam.exam_id,
                        label: exam.exam_topic
                    }));
                    setAllExams(apiResponse?.data)
                    setExamsData(transformedExams);
                }
            } catch (err) {
                setExamsData([]);
            }
        };
        getAllSystemExams();
    }, []);


    // const filteredUserData = userData.filter(user =>
    //     user.student_category === formInputData.student_category &&
    //     user.student_campus_id === formInputData.student_campus_id
    // );

    const gridColumnHeaders = [
        { field: "email", columnName: "Email" }, 
        { field: "full_name", columnName: "Full Name" },
        {
            field: "user_marks",
            inputType: "textfield",
            onChangeFunction: inputChangeHandler,
            columnName: "Student Marks",
            checkValue:'marks_out_of'
        }
    ];

    return (
        <>
            
                <div className="container-fluid ps-4 pe-4">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            fetchUsers(true);
                        }}
                        className="d-flex"
                    >
                        <FieldsRenderer
                            fieldsArray={[
                                {
                                    label: "Student Exams",
                                    className: "col-lg-4",
                                    options: examsData,
                                    inputType: "dropdown",
                                    name: "exam_topic",
                                    id: "exam_topic",
                                    required: true
                                },
                                {
                                    label: "Student Category",
                                    className: "col-lg-4",
                                    options: Settings.enumTypes,
                                    inputType: "dropdown",
                                    name: "student_category",
                                    id: "student_category",
                                    required: true
                                },
                                {
                                    label: "Student Campus",
                                    className: "col-lg-4",
                                    options: campusesList,
                                    inputType: "dropdown",
                                    name: "student_campus_id",
                                    id: "student_campus_id",
                                    required: true
                                },
                            ]}
                            updateState={setFormInputData}
                            currentState={formInputData}
                        />
                        <div className=" ps-5">
                            <button type="submit" className="connect-button w-fit p-3" disabled={saveBtnSearch}>
                                <div><span> Search </span></div>
                            </button>
                        </div>
                    </form>

                    <div className="d-flex align-items-center justify-content-between my-4">
                        <div className="total_marks_section d-flex flex-lg-row flex-column gap-3">
                            
                            <div className="d-flex align-items-center gap-3">
                                <h5 className="mb-0" style={{textTransform:"capitalize"}}>total marks: </h5> 
                                <p className="mb-0" style={{fontSize:"20px"}}>{examDetails?.marks_out_of ?? "0"}</p>
                            </div>
                        </div>
                        <form
                            onSubmit={async (e) => {
                                e.preventDefault();
                                await updateUserMarks();
                                fetchUsers();
                            }}
                        >
                            
                                
                            <div className="w-100 text-end">
                                <button type="submit" className="connect-button w-fit p-3 mt-0" disabled={saveBtn}>
                                    <div><span> Save </span></div>
                                </button>
                            </div>
                        </form>
                    </div>
                    {loading ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ height: "80vh" }}>
                            <Loader />
                        </div>
                    ) : (
                    <CustomDataGrid
                        columns={gridColumnHeaders}
                        data={userData}
                        pageSize={pageSize}
                        loadingState={loading}
                        totalRecords={totalRecords}
                        pagination={true}
                        currentPage={pageNumber}
                        onPageChange={setPageNumber}
                        onPageSizeChange={setPageSize}
                        hideDeleteBtn={true}
                        hideAddBtn={true}
                        checkboxRequired={false}
                        tabID={6}
                    />
                )}

                </div>
        </>
    );
};

export default StudentMarksListing;