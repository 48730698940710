import React from "react";
import { Links1, Links2, Links3 } from "../Navigationlinks";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavbarCS.css";
import "./Mobilenav.css";
import Mobilenav from "./Mobilenav";
import { useState, useEffect, useRef } from "react";
import styles from "./Navbar.module.css";
import { Col, Container, Row } from "react-bootstrap";
import Modal from '../Model/Modal';
import LoginSignup from "../LoginSignup/LoginSignup";
import Signup from "../SignupComponent/Signup";
import Settings from "../CommonAPICalls/Settings"
import { useSelector,useDispatch } from "react-redux";
import {logout} from "../../store/actions/userActions"
import CartPopup from '../LMS Portal/CartPopup/CartPopup';
import { showAlertMessage } from "../MasterComponents/CommonFunctionalities";

const Navbar = ({ noLinearBackground, landingPage = false, noLinearBackgroundVideo }) => {
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenTopical, setIsDropdownOpenTopical] = useState(false);
  const [isDropdownOpenMore, setIsDropdownOpenMore] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [isDropdownOpenProfileDesk, setIsDropdownOpenProfileDesk] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const navitate = useNavigate();
  const [selected5, setSelected5] = useState(false);
  const [style, setStyle] = useState({ display: "none" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginpressed, setLoginpressed] = useState(false);
  const [showCart, setShowCart] = useState(false)
  const closeModalCart = () => setShowCart(false);

  const userLoginStatus = JSON.parse(localStorage.getItem("userInfo"));
  const userLoginStatusSelector = useSelector((state)=>state.userLogin)
  const cartItems = useSelector((state)=> state.cartItems)
  const dispatch = useDispatch();


  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openSignUp = ()=>{
    setLoginpressed(false)
    setIsModalOpen(true)
  }
  const openLogin = ()=>{
    setLoginpressed(true)
    setIsModalOpen(true)
  }

  const navigate = useNavigate();
  const dropdownRef = useRef();
  const dropdownTopical = useRef();
  const dropdownMore = useRef();
  const MainDropdownRef = useRef();
  const dropdownProfileRef = useRef();

  const hamburgerdropdown = (state) => {
    setSelected5(!state);
  };

  const mouseEnterHandler = () => {
    setStyle({ display: "block" });
  };
  const mouseLeaveHandler = () => {
    setStyle({ display: "none" });
  };


  const services = [
    { name: "O Level ", link: "/notes/o-level" },
    { name: "AS Level", link: "/notes/as-level" },
    { name: "A2 Level", link: "/notes/a-level" },

  ];

  const topicals = [
    { name: "O Level ", link: "/topical-past-papers/o-level" },
    { name: "AS Level", link: "/topical-past-papers/as-level" },
    { name: "A2 Level", link: "/topical-past-papers/a-level" },
  ];

  const mores = [
    { name: "Insights ", link: "/news" },
    { name: "Contact", link: "/contact" },

  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        setIsDropdownOpenDesk(false);
      }
      if (MainDropdownRef.current && !MainDropdownRef.current.contains(event.target) && !selected5) {
        setSelected5(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk,setSelected5]);

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  const logoutHandler = () => {
    showAlertMessage("Logout Successfully ","success")
    dispatch(logout());
    window.location.reload(false);
  };

  return (
    <>
      <>
        <header
          style={{
            position: noLinearBackground && "absolute",
            zIndex: !noLinearBackground && 99,
            backgroundImage:
              noLinearBackgroundVideo || noLinearBackground
                ? "none"
                : "linear-gradient(to right, #1b1b1b , rgb(69, 69, 69))",
          }}
          className={styles.desktopHeader}
        >
          <Container>
            <div className={styles.nav}>
              <h1 onMouseEnter={mouseLeaveHandler}>
                <Link to="/">
                  <img
                    src="/images/mk-logo-white-landscape.png"
                    className={styles.logo}
                    alt="moosa-khan-logo"
                  />
                </Link>
              </h1>
              <div className={!landingPage ? styles.navList : "d-none"}>
                
              <NavLink
                  onMouseEnter={mouseLeaveHandler}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.activeLink} ${styles.link}`
                      : styles.link
                  }
                  to="/"
                >
                  Home
                </NavLink>
              <NavLink
                  onMouseEnter={mouseLeaveHandler}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.activeLink} ${styles.link}`
                      : styles.link
                  }
                  to="/about"
                >
                  About
                </NavLink>

                <div className="navigation-dropdown">
                  <div
                    className="dropdown-trigger d-flex align-items-center"
                    onMouseEnter={() => handleMouseEnter("notes")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="nav-links font-play">Notes</span>
                    <img
                          src={`/images/icons/dropdown-icon.svg`}
                          alt="arrow icon"
                          className={
                            hoveredItem === "notes"
                              ? "rotate-icon arrow-icon ms-2"
                              : "rotate-back arrow-icon ms-2"
                          }
                        />
                  </div>
                  <div
                    className={`dropdown-content ${
                      hoveredItem === "notes" ? "open" : "d-none"
                    }`}
                    onMouseEnter={() => handleMouseEnter("notes")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-item-center gap-5">
                      <div>
                        {services.map((service, index) => (
                          <div key={index}>
                            <Link
                              to={service.link}
                              className="nav-links font-play dropdown-item text-start"
                              onClick={() => setHoveredItem(null)}
                            >
                              <p className="itemsof-dropdown mb-0">
                                {Settings.enumTypes[index]?.label}
                              </p>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="navigation-dropdown">
                  <div
                    className="dropdown-trigger d-flex align-items-center"
                    onMouseEnter={() => handleMouseEnter("topicals")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="nav-links font-play">
                      Topical Past Papers
                    </span>
                    <img
                          src={`/images/icons/dropdown-icon.svg`}
                          alt="arrow icon"
                          className={
                            hoveredItem === "topicals"
                              ? "rotate-icon arrow-icon ms-2"
                              : "rotate-back arrow-icon ms-2"
                          }
                        />
                  </div>
                  <div
                    className={`dropdown-topical-content ${
                      hoveredItem === "topicals" ? "open" : "d-none"
                    }`}
                    onMouseEnter={() => handleMouseEnter("topicals")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-item-center gap-5">
                      <div>
                        {topicals.map((topical, index) => (
                          <div key={index}>
                            <Link
                              to={topical.link}
                              className="nav-links font-play dropdown-item text-start"
                              onClick={() => setHoveredItem(null)}
                            >
                              <p className="itemsof-dropdown mb-0">
                                {Settings.enumTypes[index]?.label}
                              </p>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <NavLink
                  onMouseEnter={mouseLeaveHandler}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.activeLink} ${styles.link}`
                      : styles.link
                  }
                  to="/courses"
                >
                  Courses
                </NavLink>
                <NavLink
                  onMouseEnter={mouseLeaveHandler}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.activeLink} ${styles.link}`
                      : styles.link
                  }
                  to="/contact"
                >
                  Contact
                </NavLink>
                {/* <div className="navigation-dropdown">
                  <div
                    className="dropdown-trigger d-flex align-items-center"
                    onMouseEnter={() => handleMouseEnter("more")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="nav-links font-play">More</span>
                  </div>
                  <div
                    className={`dropdown-topical-content ${
                      hoveredItem === "more" ? "open" : "d-none"
                    }`}
                    onMouseEnter={() => handleMouseEnter("more")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-item-center gap-5">
                      <div>
                        {mores.map((more, index) => (
                          <div key={index}>
                            <Link
                              to={more.link}
                              className="nav-links font-play dropdown-item text-start"
                              onClick={() => setHoveredItem(null)}
                            >
                              <p className="itemsof-dropdown mb-0">
                                {more.name}
                              </p>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div> */}
                
                
              </div>
              
              {showCart &&
                    <Modal status={showCart}  closeModal={closeModalCart}>
                        <CartPopup closeModal={closeModalCart} />
                    </Modal>
                }
              {userLoginStatus?.token ? (
                <>
                  <div className="d-flex align-items-center gap-1 user-profile-container pe-4">
                    <div className="d-flex align-items-start " style={{cursor:"pointer"}} onClick={() => { setShowCart(true) }}>
                      <img alt='cart icon' className='menu-icon' src='/images/icons/cart-icon.svg'  />
                      <div className="cart-red-icon">{cartItems.length}</div>
                    </div>
                    <p className="title mb-0 user">{userLoginStatus?.full_name}</p>

                    <div
                      className="navigation-dropdown"
                      ref={dropdownProfileRef}
                      onMouseEnter={() => {
                        setIsDropdownOpenProfileDesk(true);
                      }}
                    >
                      <div
                        className="dropdown-trigger d-flex align-items-center"
                        onMouseOver={() => {
                          setChangeArrowColor(true);
                        }}
                        onMouseOut={() => {
                          setChangeArrowColor(false);
                        }}
                      >
                        <div class="u_p_wraper" style={{backgroundImage: `url(${userLoginStatus.user_profile_image ? userLoginStatus.user_profile_image  :"/images/icons/user-icon.png"})`}}/>
                        <img
                          src={`/images/icons/dropdown-icon.svg`}
                          alt="arrow icon"
                          className={
                            isDropdownOpenProfileDesk
                              ? "rotate-icon arrow-icon"
                              : "rotate-back arrow-icon"
                          }
                        />
                      </div>
                      <div
                        className={`dropdown-content ${
                          isDropdownOpenProfileDesk ? "open" : "none-delay"
                        }`}
                        onMouseEnter={() => {
                          setIsDropdownOpenProfileDesk(true);
                        }}
                        onMouseLeave={() => {
                          setIsDropdownOpenProfileDesk(false);
                        }}
                      >
                        <div
                          className="nav-links font-Open-Sans p-2"
                          onClick={() => {
                            setIsDropdownOpenProfileDesk(false);
                            navigate("/portal")
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ textTransform: "uppercase" }}
                          >
                            Portal
                          </p>
                        </div>
                        <div
                          className="nav-links font-Open-Sans p-2"
                          onClick={() => {
                            logoutHandler()
                            setIsDropdownOpenProfileDesk(false);
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ textTransform: "uppercase" }}
                          >
                            Logout
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.buttonCont}>
                  <div className="d-flex align-items-start " style={{cursor:"pointer"}} onClick={() => { setShowCart(true) }}>
                    <img alt='cart icon' className='menu-icon' src='/images/icons/cart-icon.svg'  />
                    <div className="cart-red-icon">{cartItems.length}</div>
                  </div>
                  {!landingPage && (
                    <Link onMouseEnter={mouseLeaveHandler}>
                      <button
                        className={styles.button1}
                        onClick={() => {
                          setLoginpressed(true);
                          openModal();
                        }}
                      >
                        Login
                      </button>
                    </Link>
                  )}
                  {landingPage && (
                    <a onMouseEnter={mouseLeaveHandler}>
                      <button className={styles.button1}>Chat With Us</button>
                    </a>
                  )}
                  <Link onMouseEnter={mouseLeaveHandler} to="">
                    <button
                      className={styles.button2}
                      onClick={() => {
                        setLoginpressed(false);
                        openModal();
                      }}
                    >
                      SignUp
                    </button>
                  </Link>
                  <Modal status={isModalOpen} closeModal={closeModal}>
                    {" "}
                    {loginpressed ? <LoginSignup closeModal={closeModal} openSignUp={openSignUp}/> : <Signup closeModal={closeModal} openLogin={openLogin}/>}{" "}
                  </Modal>
                </div>
              )}
            </div>
          </Container>
        </header>
      </>

      <div className={styles.mobileHeader} ref={MainDropdownRef}>
        <header
          style={{
            width: "100%",
            position: noLinearBackground && "absolute",
            zIndex: 99,
            backgroundImage:
              noLinearBackground || noLinearBackgroundVideo
                ? "none"
                : "linear-gradient(to right, #1b1b1b , rgb(69, 69, 69))",
          }}
          className="header_cs_1"
        >
          <div className="container menu_cs_1 px-sm-3 px-4  d-flex justify-content-space-between align-content-center ">
            <h1
              className="img_h1_cs1 text-center"
              onClick={() => navitate("/")}
            >
              <img
                src="/images/mk-logo-Black-landscape.png"
                className="img-fluid"
                alt=""
              />
            </h1>
            <div
              className="bars_cs1"
              onClick={() => hamburgerdropdown(selected5)}
            >
              <img
                src={`${
                  selected5
                    ? `/icons/cross-icon.png`
                    : `/icons/hamburger-icon.png`
                }`}
                className="bars"
              />
            </div>
          </div>
        </header>
        <Mobilenav
          linear={noLinearBackground}
          landingPage={landingPage}
          transfer={selected5}
          hamburgerdropdown={hamburgerdropdown}
          
        ></Mobilenav>
      </div>
    </>
  );
};
export default Navbar;
