import { useNavigate } from "react-router-dom"
import Settings from "../../CommonAPICalls/Settings";

function truncateText(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
}

function CardBox(props) {
    return (
      <div className="card-box">
          <img src={props.cardImg} alt="someImage" />
          <div className="card-text-content text-center">
          <p className="red-text m-0 p-0">{props?.title} {props.type_id && <>({Settings.enumTypes.map((ele)=>{if(ele.value == props.type_id){return ele.label}})})</>}</p>
          <h5 className="title m-0 p-0">{truncateText(props?.content,80)}</h5>

            <div className="d-flex justify-content-lg-between justify-content-start align-items-lg-center align-items-start mb-3 buttons-area">
               
                <a  style={{textDecoration:'none'}} className="mx-auto" onClick={()=>{window.open(`${props?.driveLink}`)}}><button className="connect-button w-100" >Preview</button></a>
            </div>
          </div>
      </div>
    )
  }
  
  export default CardBox