import React, { useEffect, useState } from 'react'
import Loader from '../../UI Helpers/Simple Loading/Loader'
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities'
import { validateForm } from '../../UI Helpers/HelperFunctions/Helper'
import FieldsRenderer from '../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent'
import { useLocation, useNavigate } from 'react-router-dom'
import CommonCalls from '../../CommonAPICalls/CommonCalls'


const apiCall = new CommonCalls()

const inputsFieldAr = [
  {
    label: "Exam Topic",
    className: "col-lg-4",
    inputType: "text",
    name: "exam_topic",
    id: "exam_topic",
    required:true
  },
  {
    label: "Marks Out Of",
    className: "col-lg-4",
    inputType: "number",
    name: "marks_out_of",
    id: "marks_out_of",
    required:true
  }, 

  {
    label: "Exam Date",
    className: "col-lg-4",
    inputType: "datepicker",
    timePicker:true, 
    name: "exam_date",
    id: "exam_date",
    required:true
  }
]
 
const SystemExamsDetailView = () => {
  const [saveBtn , setSaveBtn] = useState(false)
  const [loading , setLoading] = useState(true)
  const [formInputData , setFormInputData] = useState("")
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const examId =  params.get('examid');
  const navigate = useNavigate()

  const getExamDetails = async ()=>{

      const response = await apiCall.getExamById(examId)
      if(response)
      {
        setFormInputData ({
          "exam_topic": response?.exam_topic,
          "exam_date": response?.exam_date,
          "marks_out_of": response?.marks_out_of,
          })
        
          setLoading(false)
      }
  }
  

  useEffect(()=>{
      if(examId)
      {
        setLoading(true)
        getExamDetails()
      }
      else{
        setLoading(false)
      }
  },[examId])
  


  const addExamData = async()=>{
      setSaveBtn(true)
      let validationResponse = validateForm(inputsFieldAr,formInputData);
      if(!validationResponse)
        {
          setSaveBtn(false)
          return
        }
        let apiFunction =async()=>{ return await apiCall.addNewExam(formInputData)}
        if(examId)
        {
          apiFunction = async()=>{return await apiCall.updateExam(examId,formInputData)}
        }
      const response = await apiFunction();
      if(response?.status === 201 || 202)
      {
          setSaveBtn(false)
          if (response?.status === 201)
          {
            navigate("/portal?tab_id=5")
          } 
          showAlertMessage(response?.message, "success")
          return response
      }
      else
      {
          setSaveBtn(false)
          showAlertMessage(response?.message, "fail")
          return 
      }
    }
  

  return (
    <>
    {
      loading?
      <div className='d-flex align-items-center justify-content-center' style={{height:"80vh"}}>
        <Loader/>
      </div>
      :
      <div className='exam-detail-view-container container-fluid py-4 px-4'>
      <form
            onSubmit={(e)=>{
              e.preventDefault()
              setSaveBtn(true)
              addExamData();
            }}
          >
             <div className="w-100 d-flex justify-content-between">
            <button
                type="button"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
                onClick={()=>{navigate("/portal?tab_id=5")}}
              >
                <div>
                  <span className="d-flex align-items-center"><img src="/images/icons/white-arrow-left.png" alt="arrow icon" style={{width:"25px",height:"25px"}}/> Back </span>{" "}
                </div>
              </button>
                <button type="submit" className="connect-button w-fit p-3" disabled={saveBtn}> 
                <div><span> Save </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
              </button>
              </div>
             <FieldsRenderer
                fieldsArray={inputsFieldAr}
                updateState={setFormInputData}
                currentState={formInputData}
              />
            
          </form>
    </div>
    }
  </>
   
)
}

export default SystemExamsDetailView
