import React, { useEffect, useState } from "react";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import { checkIfArrayIsEmpty, gridLinkValueFormatter, showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import { validateForm } from "../../UI Helpers/HelperFunctions/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Settings from "../../CommonAPICalls/Settings";


const apiCall = new CommonCalls();

const DetailViewSystemUser = () => {
  const [saveBtn, setSaveBtn] = useState(false);
  const [loading, setLoading] = useState(true); 

  const [formInputData, setFormInputData] = useState("");
 
  const [campusesList, setCampusesList] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get("userid");

  const getUserDetails = async () => {
    const response = await apiCall.getUserById(userId);

    if (response) {

      setFormInputData({
        email: response?.email,
        full_name: response?.full_name,
        phone_number: response?.phone_number,
        password: response?.password,
        student_category: response?.student_category,
        is_admin: response?.is_admin,
        student_campus_id: response?.student_campus_id,
        portal_access: response?.portal_access
      });
    }
    setLoading(false);
  };
  
  const getUserCampusOptions = async () => {
    const apiResponse = await apiCall.getSystemCampuses();
    if (apiResponse) {
      setCampusesList(apiResponse?.data?.map((item) => {
        return {
          value: item?.campus_id,
          label: item?.campus_name
        }
      }));
    }
  };
  
  useEffect(() => {
    const getData = async () => {

      setLoading(true); 
      await getUserCampusOptions();
      if (userId) {
        setLoading(true);
        getUserDetails(); 
      } else {
        showAlertMessage("Could not Find User", "fail");
        navigate("/portal");
      }
    }
    getData();
  }, [userId]);

  const inputsFieldAr = [
    {
      label: "Email",
      className: "col-lg-4",
      inputType: "text",
      name: "email",
      id: "email",
      required: true,
    },
    {
      label: "Full Name",
      className: "col-lg-4",
      inputType: "text",
      name: "full_name",
      id: "full_name",
      required: true,
    },
    {
      label: "Phone Number",
      className: "col-lg-4",
      inputType: "number",
      name: "phone_number",
      id: "phone_number",
      required: true,
    },
    {
      label: "Password",
      className: "col-lg-4",
      inputType: "password",
      name: "password",
      id: "password",
    },
    {
      label: "Student Category",
      className: "col-lg-4",
      options: Settings.enumTypes,
      inputType: "dropdown",
      name: "student_category",
      id: "student_category",
      required: true
    },
    {
      label: "Student Campus",
      className: "col-lg-4",
      options: campusesList,
      inputType: "dropdown",
      name: "student_campus_id",
      id: "student_campus_id",
      required: true
    },
    {
      label: "Is Admin",
      className: "col-lg-2 col-6 my-4",
      inputType: "checkbox",
      name: "is_admin",
      id: "is_admin",
    },
    {
      label: "Portal Access",
      className: "col-lg-2 col-6 my-4",
      inputType: "checkbox",
      name: "portal_access",
      id: "portal_access",
    },
  ];
 
  const updateUserData = async () => {
    setSaveBtn(true);
    let validationResponse = validateForm(inputsFieldAr, formInputData);
    if (!validationResponse) {
      setSaveBtn(false);
      return;
    }
    const response = await apiCall.updateUser(userId, formInputData);
    if (response?.status === 202) {
      setSaveBtn(false);
      showAlertMessage(response?.message, "success");
      return response;
    } else {
      setSaveBtn(false);
      showAlertMessage(response?.message, "fail");
      return;
    }
  };
  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="user-detail-view-container container-fluid ps-4 pe-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSaveBtn(true);
              updateUserData();
            }}
          >
            <div className="w-100 d-flex justify-content-between">
              <button
                type="button"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
                onClick={()=>{navigate("/portal?tab_id=0")}}
              >
                <div>
                  <span className="d-flex align-items-center"><img src="/images/icons/white-arrow-left.png" alt="arrow icon" style={{width:"25px",height:"25px"}}/> Back </span>{" "}
                </div>
              </button>
              <button
                type="submit"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
              >
                <div>
                  <span> Save </span>{" "}
                  {saveBtn ? (
                    <output
                      className="spinner-border spinner-border-sm ms-1"
                      aria-hidden="true"
                    ></output>
                  ) : (
                    ""
                  )}
                </div>
              </button>
            </div>
            <FieldsRenderer
              fieldsArray={inputsFieldAr}
              updateState={setFormInputData}
              currentState={formInputData}
            />
          </form>

        </div>
      )} 
    </>
  );
};

export default DetailViewSystemUser; 