import React, { useEffect, useState } from "react";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Settings from "../../CommonAPICalls/Settings";
import { getArrayItemFromLocalStorage } from "../../UI Helpers/TabRecorder/TabRecorderFunctions";



const apiCall = new CommonCalls();

const StudentAttendenceListing = () => {
    const [loading, setLoading] = useState(false);
    const [saveBtn, setSaveBtn] = useState(false);
    const [saveBtnSearch, setsaveBtnSearch] = useState(false);
    const [userData, setUserData] = useState([]);
    const [updatedUserData,setUpdatedUserData] =useState([])
    const [formInputData, setFormInputData] = useState({});
    const [pageNumber, setPageNumber] = useState(getArrayItemFromLocalStorage(8));
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [attendenceDetails, setAttendenceDetails] = useState();
    const [campusesList, setCampusesList] = useState([]);  
    const [dayList, setDayList] = useState([]);  

    const inputChangeHandler =  (event, changedObj, changedField) => { 
        const isNumeric = true;

        let totalAttendanceOfStudent = parseInt((dayList?.find(obj => obj.value == attendenceDetails[event.target.getAttribute("data-checkValue")])?.label)?.split(" ")[0]);
 
        if(parseInt(event.target.value) > totalAttendanceOfStudent)
        {
            showAlertMessage("Could not exceed the value from total","fail")
            return
        }
        if(isNumeric)
        {
            setUserData((prev) => {
                
                const userDataIndex = prev.findIndex((data) => data.email == changedObj.email);
               
                if (userDataIndex === -1) return prev; 
                const updatedUserData = [...prev];
                updatedUserData[userDataIndex][changedField] = event.target.value;
                return updatedUserData;
            });
    
            setUpdatedUserData((prevUpdated) => {
                const existingIndex = prevUpdated.findIndex((data) => data.email === changedObj.email);
                const newData = { ...changedObj, [changedField]: event.target.value };
        
                if (existingIndex !== -1) {
                    const updatedArray = [...prevUpdated];
                    updatedArray[existingIndex] = newData;
                    return updatedArray;
                } else {
                    return [...prevUpdated, newData];
                }
            });

        }
        else
        {
            showAlertMessage("Please insert a valid Numeric Value","fail")
        }
    }

    const fetchUsers = async (formRequest=false) => {
        setLoading(true);
        const { month, year, total_attendence,course_type_id,student_campus_id } = formInputData;
 
        if ((!month || !year || !total_attendence || !course_type_id || !student_campus_id) && formRequest) {
            showAlertMessage("Please select all fields.", "fail");
            setLoading(false);
            return;
        } 
       
        setAttendenceDetails({attendance_out_of:total_attendence})

        setsaveBtnSearch(true)

        try {
            const response = await apiCall.getUserAttendenceDetails(
                month,
                year,
                total_attendence,
                course_type_id,
                student_campus_id,
                pageNumber,
                pageSize
            );

            if (response && response.data) {
                setUserData(response?.data)
                setTotalRecords(response?.total_records); 
            } else {
                setUserData([]);
                if(formRequest)
                {
                    showAlertMessage("Failed to fetch student data", "fail");
                }
            }
        } catch (error) { 
            setUserData([]);
            if(formRequest)
            {
                showAlertMessage("Failed to fetch data", "fail");
            }
        } finally {
            setLoading(false);
            setsaveBtnSearch(false)
        }
    };
    const getUserCampusOptions = async () => {
        const apiResponse = await apiCall.getSystemCampuses();
        if (apiResponse) {
          setCampusesList(apiResponse?.data?.map((item) => {
            return {
              value: item?.campus_id,
              label: item?.campus_name
            }
          }));
        }
      };
    const getUserDays = async () => {
        const apiResponse = await apiCall.getSystemDays();
        if (apiResponse) {
          setDayList(apiResponse?.data?.map((item) => {
            return {
              value: item?.enum_id,
              label: item?.enum_name
            }
          }));
        }
      };
    
    useEffect(() => {
        fetchUsers();
        getUserCampusOptions();
        getUserDays()
    }, [ pageNumber, pageSize]);

   

    const updateUserMarks = async () => {
        setLoading(true);
        setSaveBtn(true) 
       
       const withAttendanceId = updatedUserData.filter(item => item.student_attendence_id !== null);
       const withoutAttendanceId = updatedUserData.filter(item => item.student_attendence_id === null);
       const withAttendenceData = withAttendanceId.map((data)=>{return(
        {
            student_attendence_id:data?.student_attendence_id,
            month:parseInt(formInputData?.month),
            year:parseInt(formInputData?.year),
            user_id:data?.user_id,
            total_attendence:parseInt(attendenceDetails?.attendance_out_of),
            student_attendence:data?.student_attendence==""? 0:parseInt(data?.student_attendence) ,
        }
        )})
        const withoutAttendenceData = withoutAttendanceId.map((data)=>{return(
            {
                month:parseInt(formInputData?.month),
                year:parseInt(formInputData?.year),
                user_id:data?.user_id,
                total_attendence:parseInt(attendenceDetails?.attendance_out_of),
                student_attendence:data?.student_attendence==""? 0:parseInt(data?.student_attendence),
            }
        )})

        try {
                if(withoutAttendenceData.length > 0)
            {
                
                const response = await apiCall.updateUserAttendencesWithOutAttendenceid(
                    
                    withoutAttendenceData
                );
                if (response.status ===201) {
                    showAlertMessage(response?.message, "success");
                    setUpdatedUserData([])
                    fetchUsers();
                } else {
                    showAlertMessage(response?.message, "fail");
                }

            }
            if(withAttendenceData.length > 0)
            {
                const response = await apiCall.updateUserAttendenceWithAttendenceid(
                    withAttendenceData
                );
                if (response.status ===202) {
                    showAlertMessage(response?.message, "success");
                    setUpdatedUserData([])
                    fetchUsers();
                } else {
                    showAlertMessage(response?.message, "fail");
                }

            }
        } catch (error) { 
            showAlertMessage("Failed to update user data", "fail");
        } finally {
             setSaveBtn(false)
            setLoading(false);
        }
    };

    const gridColumnHeaders = [
        { field: "email", columnName: "Email" },
        { field: "full_name", columnName: "Full Name" },
        {
            field: "student_attendence",
            inputType: "textfield",
            onChangeFunction: inputChangeHandler,
            columnName: "Student Attendance",
            checkValue:"attendance_out_of"
        }
    ];

    return (
        <>
            
                <div className="container-fluid ps-4 pe-4">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            fetchUsers(true);
                        }}
                        className="d-flex"
                    >
                        <FieldsRenderer
                            fieldsArray={[
                                {
                                    label: "Month",
                                    className: "col-lg-4",
                                    options: Settings.monthsOption,
                                    inputType: "dropdown",
                                    name: "month",
                                    id: "month",
                                    required: true
                                },
                                {
                                    label: "Year",
                                    className: "col-lg-4",
                                    options: Settings.yearOption,
                                    inputType: "dropdown",
                                    name: "year",
                                    id: "year",
                                    required: true
                                },
                                {
                                    label: "Total Class Days",
                                    className: "col-lg-4",
                                    options: dayList,
                                    inputType: "dropdown",
                                    name: "total_attendence",
                                    id: "total_attendence",
                                    required: true
                                } ,
                                {
                                    label: "Course Type",
                                    className: "col-lg-4",
                                    inputType: "dropdown",
                                    options: Settings.enumTypes,
                                    name: "course_type_id", 
                                    id: "course_type_id",
                                    required: true,
                                  }, 
                                  {
                                    label: "Student Campus",
                                    className: "col-lg-4",
                                    options: campusesList,
                                    inputType: "dropdown",
                                    name: "student_campus_id",
                                    id: "student_campus_id",
                                    required: true
                                  },
                            ]}
                            updateState={setFormInputData}
                            currentState={formInputData}
                        />
                        <div className=" ps-5">
                            <button type="submit" className="connect-button w-fit p-3" disabled={saveBtnSearch}>
                                <div><span> Search </span></div>
                            </button>
                        </div>
                    </form>

                    <div className="d-flex align-items-center justify-content-end my-4"> 
                        <form
                            onSubmit={async (e) => {
                                e.preventDefault();
                                await updateUserMarks();
                            }}
                        >
                            
                                
                            <div className="w-100 text-end">
                                <button type="submit" className="connect-button w-fit p-3 mt-0" disabled={saveBtn}>
                                    <div><span> Save </span></div>
                                </button>
                            </div>
                        </form>
                    </div>
                    {loading ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ height: "80vh" }}>
                            <Loader />
                        </div>
                    ) : (
                    <CustomDataGrid
                        columns={gridColumnHeaders}
                        data={userData}
                        pageSize={pageSize}
                        loadingState={loading}
                        totalRecords={totalRecords}
                        pagination={true}
                        currentPage={pageNumber}
                        onPageChange={setPageNumber}
                        onPageSizeChange={setPageSize}
                        hideDeleteBtn={true}
                        hideAddBtn={true}
                        checkboxRequired={false}
                        tabID={8}
                    />
                )}

                </div>
        </>
    );
};

export default StudentAttendenceListing;