import React from 'react'
import PlainHero from '../components/PlainHero/PlainHero'

function NotFound() {
  return (
        <>
            <PlainHero
             heading="404"
             subHeading=""
             content="Could Not Find the Requested Page"
            />
        </>
  )
}

export default NotFound