import React, { useState, useEffect } from "react";
import "./ChemistryATPSessionPartTwo.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";
import CommonCalls from "../../../CommonAPICalls/CommonCalls";
import Loader from "../../../UI Helpers/Simple Loading/Loader";
import { useSelector } from "react-redux";
import { checkIfArrayIsEmpty } from "../../../MasterComponents/CommonFunctionalities";

function ChemistryATPSessionPartTwo({changeScreenFunc}) {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const userLoginStatus = useSelector((state) => state.userLogin);

  const enrolledCoursesIds = userLoginStatus.userInfo?.enrolled_courses;

  const apiCall = new CommonCalls();

  const getEnrolledCourses = async () => {
    if (checkIfArrayIsEmpty(enrolledCoursesIds)) {
      setLoading(false); 
      return;
    }
    setLoading(true);
    const response = await apiCall.getEnrolledCourseById(enrolledCoursesIds);
    if (response) {
      setError(false);
      setLoading(false);
      return response;
    } else {
      setLoading(false);
      setError("Could not load data from server");
      return;
    }
  };

  useEffect(() => {
    const getApiData = async () => {
      const enrolledCoursesResponse = await getEnrolledCourses();
      if (!error) {
        setCourses(enrolledCoursesResponse);
      }
    };

    getApiData();
  }, []);

  return (
    <div className="main-cont">
      {" "}
      <h3 className="h1_main mt-2 mb-0">Enrolled Courses</h3>
      {loading ? (
        <Loader />
      ) : (
        <div className="EnrolledCard-container py-2 ">
          {
            courses ?

            <div
            className="card-list row gy-4 py-4 mx-0 w-100 px-2"
          >
            {courses?.map((course, ind) => (
              <div className="col-lg-4" key={ind} style={{ height: "auto" }}>
                <CardBox
                  className="h-100"
                  cardImg={"./images/creatives/chemistryATPBanner.png"}
                  title={course.course_name}
                  price={course.course_price}
                  type_id={course.course_type_id}
                  content={course.course_description}
                  driveLink={course.course_drive_link}
                  slug_url={course.slug_url}
                />
              </div>
            ))}
          </div>

            :
            (
              <div className="d-flex flex-column align-items-center justify-content-center h-100 py-5">
                <h6 className="text-center">No courses are currently enrolled. <span onClick={() => {
                    changeScreenFunc(2);
                  }} style={{cursor:'pointer',color:"#007DFF"}}>Click here</span> to explore and purchase courses.</h6>
              </div>
            )
          }
         
        </div>
      )}
    </div>
  );
}

export default ChemistryATPSessionPartTwo; 