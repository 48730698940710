import React from 'react';
import { showAlertMessage } from '../../../components/MasterComponents/CommonFunctionalities';

const FileUploaderWidget = ({setSelectedFile, selectedFile}) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0]; 
        if (file) { 
            if (file.type === 'video/mp4') { 
                if (file.size <= 1024 * 1024 * 1024) { 
                    setSelectedFile(file); 
                } else {
                    showAlertMessage('File size exceeds 1GB limit!', 'fail');
                }
            } else {
                showAlertMessage('Please select an MP4 video file!', 'fail');
            }
        }
    };

    return (
        <div className='uploader-component-wrapper'>
            <div className='upload'>
                <img alt="upload" src="/icons/upload.svg" />
                <div>
                    {selectedFile ? (
                        <h3 className="h3_main">Selected: <p className='para_main'>{selectedFile?.name}</p></h3>
                    ) : (
                        <>
                            <h3 className="h3_main">Select a video file or drag and drop here</h3>
                            <p className="para_main">MP4, file size no more than 1 GB</p>
                        </>
                    )}
                </div>

                <input
                    type="file"
                    accept="video/mp4"  
                    onChange={handleFileChange}
                    style={{ display: 'none' }} 
                    id="fileInput"
                />
                <label htmlFor="fileInput" className="button_main">SELECT FILE</label>
            </div>
        </div>
    );
};
export const ImageFileUploaderWidget = ({ selectedFile, id, onChange, name, label,required }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        if (file) {
            const isValidType = file.type === 'image/png' || file.type === 'image/jpeg';
            if (!isValidType) {
                showAlertMessage('Please select a PNG or JPG image file!', 'fail');
                return;
            }

            const isValidSize = file.size <= 250 * 1024; 
            if (!isValidSize) {
                showAlertMessage('File size exceeds 250 KB limit!', 'fail');
                return;
            }

            onChange(event, name);
        }
    };

    return (
        <div className='image-upload-container'>
            <label htmlFor={id} className='main-label'>
                {label}{required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <div className='uploader-component-wrapper my-2 mb-4 p-0'>
                <div className='upload d-flex py-3 px-3 w-100'>
                    <div >
                        {selectedFile ? (
                            <h3 className="h4_main ">Selected: <p className='para_main '>{(selectedFile?.name).slice(1,50)}</p></h3>
                        ) : (
                            <>
                                <h3 className="h4_main text-start">Select an image file</h3>
                                <p className="para_main text-start" style={{fontSize:"15px"}}>Image file type: PNG or JPG, size no more than 250 KB</p>
                            </>
                        )}
                    </div>

                    <input
                        type="file"
                        accept="image/png, image/jpeg" 
                        onChange={handleFileChange}
                        style={{ display: 'none' }} 
                        id={"fileInput"}
                        name={name}
                    />
                    <label htmlFor="fileInput" className="button_main">SELECT FILE</label>
                </div>
            </div>
        </div>
    );
};


export default FileUploaderWidget;