import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import Loader from '../../UI Helpers/Simple Loading/Loader';
import { useSelector } from 'react-redux';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

const UserMarksAttendanceChart = ({dashboardDisplay=false}) => {
    const [userExamAttendanceDetails, setUserExamAttendanceDetails] = useState([]);
    const [examDetails, setExamDetails] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [releventExams, setReleventExams] = useState(null);
    const apiCalls = new CommonCalls();
    const marksChartRef = useRef();
    const reportRef = useRef();
    const spacerRef = useRef();
    const [chartImage, setChartImage] = useState(null); 
    const studentData = useSelector((state) => state.userLogin).userInfo;

    useEffect(() => {
        const fetchData = async () => {
            await getUserExamDetails();
            await getUserExamAttendanceDetails();
        };

        fetchData();
    }, []);

    const getUserExamAttendanceDetails = async () => {
        setLoading(true);
        try {
            const apiResponse = await apiCalls.getExamAttendanceDetails();
            if (apiResponse) {
                setUserExamAttendanceDetails(apiResponse.data);
            }
        } catch (err) {
            setUserExamAttendanceDetails([]);
        } finally {
            setLoading(false);
        }
    };

    const getUserExamDetails = async () => { 
        try {
            const apiResponse = await apiCalls.getExamDetails();
            if (apiResponse) {
                setExamDetails(apiResponse.data);
            }
        } catch (err) {
            setExamDetails([]); 
        }
    };

    useEffect(() => {
        if (userExamAttendanceDetails && Array.isArray(userExamAttendanceDetails) && userExamAttendanceDetails.length && 
            examDetails && Array.isArray(examDetails) && examDetails.length) {
    
            const labels = [];
            const data = [];
    
            userExamAttendanceDetails.forEach(attendance => {
                const examDetail = examDetails.find(exam => exam.exam_id === attendance.exam_id);
                if (examDetail) {
                    labels.push(examDetail.exam_topic);
                    const percentage = (attendance.user_marks / parseInt(examDetail.marks_out_of, 10)) * 100;
                    data.push(percentage);
                }
            });
    
            const relevantExamDetails = examDetails.filter(exam => 
                userExamAttendanceDetails.some(attendance => attendance.exam_id === exam.exam_id)
            );
    
            setReleventExams(relevantExamDetails);
    
            if (labels.length && data.length) {
                setChartData({
                    labels,
                    datasets: [
                        {
                            label: 'Marks (%)',
                            data,
                            backgroundColor: '#36A2EB',
                        },
                    ],
                });
            } else {
                setChartData(null);
            }
        } else {
            setChartData(null); 
        }
    }, [userExamAttendanceDetails, examDetails]);
    

    const captureChartImage = async () => {
        if (marksChartRef.current) {
            const canvas = await html2canvas(marksChartRef.current, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
            setChartImage(()=>imgData); 
        }
    };

    const handleDownloadPDF = async () => {
        await captureChartImage();
        const element = reportRef.current;
        const spacer = spacerRef.current;
        spacer.style.display = 'block';
        element.style.display = 'block';
    
        const options = {
            filename: `${studentData.full_name}_Marks_Report.pdf`,
            jsPDF: { unit: 'pt', format: 'a4' },
            html2canvas: { scale: 2 }
        };
    
        setTimeout(() => {
            html2pdf().set(options).from(element).save().then(() => {
                element.style.display = 'none';
                spacer.style.display = 'none';
            });
        }, 200);
    };
    
    const options = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                max: 100,
                title: {
                    display: true,
                    text: 'Percentage (%)',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Exam Topic',
                },
            },
        },
        barPercentage: 0.2,
        plugins: {
            legend: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: true,
    };
    
    const getObtainedMarksColor = (marks,exam) => {
        if (marks === undefined || marks === null) return 'black'; 
    
        const percentage = (marks / exam.marks_out_of) * 100;
    
        if (percentage >= 80) {
            return "green";
        } else if (percentage >= 60) {
            return "yellowgreen"; 
        } else if (percentage >= 40) {
            return "yellow"; 
        } else if (percentage >= 20) {
            return "orange"; 
        } else {
            return "red"; 
        }
    };
    

    return (
        <div>
            {loading ? (
                <div className='mt-5'> <Loader /> </div>
            ) : (
                chartData ? (
                    <>
                        {
                            !dashboardDisplay &&
                            <div className='d-flex flex-lg-row flex-column align-items-center justify-content-end gap-3 py-3 px-3'>
                                {chartData &&
                                    <button
                                        type="button"
                                        className="connect-button p-3 mt-0"
                                        onClick={handleDownloadPDF}
                                    >
                                        <div>
                                            <span> Generate Report </span>
                                            <img src='/images/icons/report-icon.png' alt='report icon' />
                                        </div>
                                    </button>
                                }
                            </div>
                        }
                    
                        <div ref={marksChartRef} className={`${dashboardDisplay?"dashboardDisplay-container":""} d-flex align-items-center justify-content-center`}>
                            <Bar
                                data={chartData}
                                options={options}
                            />
                        </div>

                        {/* Hidden report section for PDF generation */}
                        <div ref={spacerRef} style={{height:"500vh",display:'none'}}></div>
                        <div ref={reportRef} style={{ display: 'none', padding: '20px',marginTop:"20px" }}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between", gap:"20px",marginBottom:"50px"}}>
                                <img src='/images/mk-logo-white-landscape.png' alt='logo'/>
                                <h2 style={{ color: '#EE202D' }}>Exams <span style={{color:"#1F2F54"}}>Marks Report</span></h2>
                            </div>

                            <div style={{display:"flex",alignItems:"start", gap:"20px",marginBottom:"20px"}}>
                                <div style={{marginBottom: '10px',border:"1px solid #B5B5B5",borderRadius:"5px",paddingBottom:"10px",width:"100%"} }>
                                    <div style={{ backgroundColor: '#1D1F5F', color: '#FFFFFF', padding: '10px',borderRadius:"5px",marginBottom:"20px",textAlign:"center" }}>
                                        <h5 style={{fontWeight:"bold"}}>Student Details</h5>
                                    </div>
                                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",paddingLeft:"15px",paddingRight:"15px"}}>
                                        <p>Name: {studentData?.full_name}</p>
                                        <p>Phone: {studentData?.phone_number}</p>
                                        <p>Email: {studentData?.email}</p>
                                    </div>
                                </div>

                            </div>

                            <div >
                            
                            {
                                releventExams && 
                                <div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
                                    {releventExams.map((exam)=>{
                                      return(  
                                      <div style={{width:"50%",paddingRight:"10px"}}>
                                      <div style={{backgroundColor:"#EBEFF3",borderRadius:"10px",padding:"10px",borderBottom:"5px solid #3CA3E9",marginBottom:"20px"}}>
                                            <h5 style={{textAlign:"center",marginTop:"15px"}}>{`${exam.exam_topic}`}</h5>
                                            <div style={{display:"flex",alignItems:'center',justifyContent:"space-evenly",gap:"10px"}}>
                                            <div style={{textAlign:"center"}}>
                                                <p>Total Marks</p>
                                                <p style={{fontWeight:"900",fontSize:"25px",color:"#1D1F5F"}}>{`${exam.marks_out_of}`}</p>
                                            </div>
                                           
                                            <div style={{textAlign:"center"}}>
                                                <p>Obtain Marks</p>
                                                <p
                                                    style={{
                                                        fontWeight: "900",
                                                        fontSize: "25px",
                                                        color: getObtainedMarksColor(
                                                            (userExamAttendanceDetails.find((ele) => exam.exam_id === ele.exam_id))?.user_marks,exam
                                                        )
                                                    }}
                                                >
                                                    {(userExamAttendanceDetails.find((ele) => exam.exam_id === ele.exam_id))?.user_marks}
                                                </p>
                                            </div>
                                            <div style={{textAlign:"center"}}>
                                                <p>Exam Date</p>
                                                <p style={{fontWeight:"900",fontSize:"20px",color:"#1D1F5F",paddingTop:"5px"}}>{`${new Date(exam.exam_date).toLocaleDateString()}`}</p>
                                            </div>

                                            </div>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            }
                            
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='d-flex align-items-center justify-content-center' style={{height:`${!dashboardDisplay?"400px":"150px"}` ,textAlign:"center",width:"100%"}}>
                        <p>No exam results available yet.</p>
                    </div>
                )
            )}
        </div>
    );
};

export default UserMarksAttendanceChart;
