import React, { useEffect, useState } from "react";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import { validateForm } from "../../UI Helpers/HelperFunctions/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Settings from "../../CommonAPICalls/Settings";

const apiCall = new CommonCalls();

const SystemOnlineClassesDetailView = () => {
  const [saveBtn, setSaveBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formInputData, setFormInputData] = useState("");
  const [campusesList, setCampusesList] = useState([]);
  const [stdCategory, setStdCategory] = useState(-1);
  const [stdCampus, setStdCampus] = useState(-1);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const classId = params.get("classid");
  const navigate = useNavigate()

  const getClassDetails = async () => {
    const response = await apiCall.getClassById(classId);
    if (response) {
      setFormInputData({
        class_subject: response?.class_subject,
        session_date_time: response?.session_date_time,
        class_topic: response?.class_topic,
        zoom_live_link: response?.zoom_live_link,
        course_type_id: response?.course_type_id,
        student_campus_id: response?.student_campus_id,
        enrolled_users: response?.enrolled_users 
      });

      setLoading(false);
    }
  };
  const getUserCampusOptions = async () => {
    const apiResponse = await apiCall.getSystemCampuses();
    if (apiResponse) {
      setCampusesList(apiResponse?.data?.map((item) => {
        return {
          value: item?.campus_id,
          label: item?.campus_name
        }
      }));
    }
  };

  useEffect(() => { 
    getUserCampusOptions();
    if (classId) {
      setLoading(true);
      getClassDetails();
    } else {
      setLoading(false);
    }
  }, [classId]);

  useEffect(()=> {
    setStdCategory(formInputData?.course_type_id) 
  },[formInputData.course_type_id]);

  useEffect(()=> {
    setStdCampus(formInputData?.student_campus_id) 
  },[formInputData.student_campus_id]);

  const addClassData = async () => {
    setSaveBtn(true);
    let validationResponse = validateForm(inputsFieldAr, formInputData);
    if (!validationResponse) {
      setSaveBtn(false);
      return;
    }
    let apiFunction = async () => {
      return await apiCall.addNewClass(formInputData);
    };
    if (classId) {
      apiFunction = async () => {
        return await apiCall.updateClass(classId, formInputData);
      };
    }
    const response = await apiFunction();
    if (response?.status === 201 || 202) {
      setSaveBtn(false);
      if (response?.status === 201)
        {
          navigate("/portal?tab_id=10")
        } 
      showAlertMessage(response?.message, "success");
      return response;
    } else {
      setSaveBtn(false);
      showAlertMessage(response?.message, "fail");
      return;
    }
  };

  const inputsFieldAr = [
    {
      label: "Class Topic",
      className: "col-lg-4",
      inputType: "text",
      name: "class_topic",
      id: "class_topic",
      required: true,
    },
    {
      label: "Class Subject",
      className: "col-lg-4",
      inputType: "text",
      name: "class_subject",
      id: "class_subject",
      required: true,
    },
    {
      label: "Session Date Time",
      className: "col-lg-4",
      inputType: "datepicker",
      timePicker: true, 
      name: "session_date_time",
      id: "session_date_time",
      required: true,
    },
    {
      label: "Zoom Live Link",
      className: "col-lg-4",
      inputType: "text",
      name: "zoom_live_link",
      id: "zoom_live_link",
      required: true,
    }, 
    {
      label: "Course Type",
      className: "col-lg-4",
      inputType: "dropdown",
      options: Settings.enumTypes,
      name: "course_type_id", 
      id: "course_type_id",
      required: true,
    }, 
    {
      label: "Student Campus",
      className: "col-lg-4",
      options: campusesList,
      inputType: "dropdown",
      name: "student_campus_id",
      id: "student_campus_id",
      required: true
    },
    {
      label: "Users (For Granting Course Access)",
      className: "col-lg-4",
      inputType: "multi_dropdown", 
      defaultValues: formInputData?.enrolled_users ?? [],
      name: "enrolled_users", 
      id: "enrolled_users",
      enableSearch:true,
      studentCataGoryApiData: stdCategory,
      studentCampusApiData: stdCampus,
      required: true,
    }
  ];

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="online-class-detail-view-container container-fluid py-4 px-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSaveBtn(true);
              addClassData();
            }}
          >
            <div className="w-100 d-flex justify-content-between">
            <button
                type="button"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
                onClick={()=>{navigate("/portal?tab_id=10")}}
              >
                <div>
                  <span className="d-flex align-items-center"><img src="/images/icons/white-arrow-left.png" alt="arrow icon" style={{width:"25px",height:"25px"}}/> Back </span>{" "}
                </div>
              </button>
              <button
                type="submit"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
              >
                <div>
                  <span> Save </span>{" "}
                  {saveBtn ? (
                    <output
                      className="spinner-border spinner-border-sm ms-1"
                      aria-hidden="true"
                    ></output>
                  ) : (
                    ""
                  )}
                </div>
              </button>
            </div>
            <FieldsRenderer
              fieldsArray={inputsFieldAr}
              updateState={setFormInputData}
              currentState={formInputData}
            />
          </form>
        </div>
      )}
    </>
  );
};

export default SystemOnlineClassesDetailView; 