import React, { useState } from 'react';
import './PastPapers.css';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/actions/cartActions';
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';
import Modal from '../Model/Modal';
import LoginSignup from '../LoginSignup/LoginSignup';
import Signup from '../SignupComponent/Signup';

const getTextColor = (() => {
  const randomColors = ["#A8B5EB", "#E17AA1", "#0EC2A8"];
  let index = 0;
  
  return () => randomColors[index++ % randomColors.length];
})();

const PastPapers = ({backgroundColor, title, content, height, padding, pastPapersContent=[],bgImg="bg0"}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signUpform, setSignUpform] = useState(true);
  const dispatch = useDispatch()
  const userLoginStatus = useSelector((state)=>state.userLogin)
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {setIsModalOpen(false)};
  const openSignUp = ()=>{
    setSignUpform(true)
    setIsModalOpen(true)
  }
  const openLogin = ()=>{
    setSignUpform(false)
    setIsModalOpen(true)
  }

  return (
    <div className={`wrapper-div ${bgImg == "bg1" && "bg-1"} ${bgImg == "bg2" && "bg-2"}`} style={{ backgroundColor: backgroundColor, padding}}>

    <div className="container pastpaper-container">
      
      <h1 className='h1_main'>{title}</h1>
      <p className="para_main">
        {content}
      </p>
      <div className="papers">
        {pastPapersContent?.map((paper, index) => (
          <div className="paper-card d-flex flex-column justify-content-between" key={index}>
              <h4 className="card-subtitle" style={{ color: getTextColor() }}>{paper?.topical_paper_title}</h4>
              <h3 className="card-title" >{paper?.topical_paper_year}</h3>
            <div
              className='heighted-content'
            >
              <p className="card-para">{paper?.topical_paper_description}</p>
            </div>
            { !paper?.free_view && <p className="card-price">PKR {paper?.topical_paper_price} /-</p> }
            {
              !paper?.free_view ?
              <button className="add-to-cart" style={{ backgroundColor: getTextColor() }}
                onClick={()=>{dispatch(addToCart(paper,{enrolled_courses: userLoginStatus?.userInfo?.enrolled_courses,enrolled_past_papers: userLoginStatus?.userInfo?.enrolled_past_papers}))}}
              >Add to Cart</button>
            :
              <div className="d-flex justify-content-lg-between justify-content-center align-items-lg-center align-items-start mb-3 buttons-area">
                <a
                  style={{
                    textDecoration: 'none',
                    pointerEvents: !paper?.topical_paper_drive_link ? 'none' : 'auto',
                    opacity: !paper?.topical_paper_drive_link ? 0.5 : 1
                  }}
                  aria-disabled={!paper?.topical_paper_drive_link}
                  onClick={() => {
                    if (paper?.topical_paper_drive_link ) {
                      if(userLoginStatus.userInfo?.token)
                      {
                        window.open(paper.topical_paper_drive_link);
                      }
                      else{
                        setIsModalOpen(true)
                        showAlertMessage("Please Login to access FREE Past Papers","fail")
                      }
                    }
                  }}
                >
                  <button
                    className="connect-button w-100"
                    disabled={!paper?.topical_paper_drive_link}
                    style={{
                      cursor: !paper?.topical_paper_drive_link ? 'not-allowed' : 'pointer',
                    }}
                  >
                    Open Drive Link
                  </button>
                </a>
              </div> 
            }
          </div>
        ))}
        <Modal status={isModalOpen} closeModal={closeModal} >
           {!signUpform ? <LoginSignup closeModal={closeModal} openSignUp={openSignUp}/> : <Signup closeModal={closeModal} openLogin={openLogin}/>}{" "}
         </Modal>
      </div>
    </div>
    </div>
  );
};

export default PastPapers;