import React, { useEffect, useState } from 'react';
import FileUploaderWidget from './FileUploaderWidget';
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities';
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import CustomDataGrid from '../../UI Helpers/DataGrid/CustomDataGrid';
import { getArrayItemFromLocalStorage } from '../../UI Helpers/TabRecorder/TabRecorderFunctions';

const VideoUploaderAWS = () => {
    const [saveBtn, setSaveBtn] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [videoData, setVideoData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(getArrayItemFromLocalStorage(2));
    const [pageSize, setPageSize] = useState(10);
    const [submissionMessage, setSubmissionMessage] = useState('');
  
    const apiCalls = new CommonCalls();
  
    useEffect(() => { getAllSystemVideos(); }, []);
  
    const getAllSystemVideos = async () => { 
      try {
        const apiResponse = await apiCalls.getVideoDetails();
        if (apiResponse) {
            setVideoData(apiResponse?.data);
            setTotalRecords(apiResponse?.data?.length);
            setLoading(false);
        }
      } catch (err) {
        setVideoData([]);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    const uploadFileToAWS = async () => {
        setSaveBtn(true);

        const formData = new FormData();
        formData.append("video", selectedFile);
 
        const uploadFileResponse = await apiCalls.addNewFileToAWS(
            formData,
            'multipart/form-data'
        );

        if (uploadFileResponse?.status == 201) {
            showAlertMessage(uploadFileResponse?.message, "success");
            setSelectedFile('');
            setSaveBtn(false);
            setSubmissionMessage("Video uploaded successfully. Please refresh the page to update the grid.")
        } else {
            showAlertMessage("File couldnt be uploaded!", "fail");
            setSaveBtn(false);
            setSubmissionMessage("File couldnt be uploaded!")
        }
    };

    const onSaveBtnClick = () => { 
        if(!selectedFile) {
            showAlertMessage('Please select a file then click on Save button!', 'fail');
            return;
        };
        setSubmissionMessage('')
        uploadFileToAWS();
    };

    const gridColumnHeaders = [
        { field: 'name', columnName: 'Name' },
      ];

    return (
        <div className='container-fluid px-4'>
            <div className="d-flex flex-column justify-content-start align-items-end mb-5">
                <button
                    type="submit"
                    className="connect-button w-fit p-3"
                    disabled={saveBtn}
                    onClick={onSaveBtnClick}
                >
                    <div>
                        <span> Save </span>{" "}
                        {saveBtn ? (
                            <output
                                className="spinner-border spinner-border-sm ms-1"
                                aria-hidden="true"
                            ></output>
                        ) : ( "" )}
                    </div>
                </button>
                        {
                            submissionMessage &&
                            <div className="d-flex flex-column align-items-center justify-content-center mt-4 w-100"> 
                                <div style={{border:"1px solid red",color:"white",borderRadius:"20px",textAlign:"center",padding:"12px",}}>
                                    <h4 className="h1_main mb-0" style={{color: 'black'}}>{submissionMessage}</h4>
                                </div>
                            </div>
                        }
            </div>
            <FileUploaderWidget selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
            <CustomDataGrid
                columns={gridColumnHeaders} 
                data={videoData} 
                hideAllBtns={true}
                pageSize={pageSize} 
                loadingState={loading}
                pagination={false}
                totalRecords={totalRecords}
                currentPage={pageNumber}
                onPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
                checkboxRequired={false}
                tabID={2}
            />
        </div>
    )
}

export default VideoUploaderAWS;