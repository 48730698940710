import { fetchUserDetails } from "../../store/actions/userActions";
import { showAlertMessage } from "../MasterComponents/CommonFunctionalities";
import Settings from "./Settings";
import SystemApiCallSettings from "./SystemApiCallSettings";

export default class CommonCalls {

    /* GET API Calls */
    async getSystemUsers(page_number = 1, page_size = 10, search_key = null,studentCatagory=-1,student_campus_data=-1) {
        
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/all_users?page_number=${page_number}&page_size=${page_size}${search_key ? `&search=${search_key}` : ''}${studentCatagory > -1 ? `&student_category=${studentCatagory}` : ''}${student_campus_data > -1 ? `&student_campus_id=${student_campus_data}` : ''}`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getUserAttendenceDetails(month,year,total_classes,course_type_id,student_campus_id,page_number=1,page_size=10) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_users_for_attendance?month=${month}&year=${year}&page_number=${page_number}&page_size=${page_size}&total_classes=${total_classes}&student_category=${course_type_id}&student_campus_id=${student_campus_id}`,{ token: userInfoToken },true,{},'application/json',true);
            
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async getUserMarksAndAttendenceDetails(stdCatagory,campusId,exam_topic,page_number=1,page_size=10) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_users_by_category_campus?student_category=${stdCatagory}&exam_id=${exam_topic}&student_campus_id=${campusId}&page_number=${page_number}&page_size=${page_size}`,{ token: userInfoToken },true,{},'application/json',true);
            
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async getSystemNotes() {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalNotesUrl + `/get_all_topical_notes`);
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getSystemOnlineClasses() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiOnlineClassesUrl + `/get_all_online_classes_details`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async getUserOnlineClasses() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfoToken?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiOnlineClassesUrl + `/get_all_online_classes_details_by_user/${userInfoToken?.user_id}`, { token: userInfoToken?.token });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getSystemTopicalPastPapers() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalPastPapersUrl + `/get_all_topical_past_papers`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getSystemCampuses() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiCampusUrl + `/get_all_campuses`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async getSystemDays() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_enum_details`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getSystemCourseLessons(lesson_id) {
        try {
            const res = await SystemApiCallSettings('GET', `${Settings.apiCourseUrl}/get_lessons_by_course/${lesson_id}`,{},true,{},'application/json',true);
            if (res?.status === 200) {
                return res;
            } else {
                return { data: [] };
            }
        } catch (error) {
            return { data: [] };
        }
    }

    async getSystemCourses() {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl + '/get_all_courses');
            if (res.status === 200) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async getSystemCoursesByType(courseType, enrolledCourse = []) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl + `/get_courses_by_type/${courseType}`, {}, true, {}, 'application/json', true);
            if (res.status === 200) {
                if (enrolledCourse) {
                    return res.data.filter(course => !enrolledCourse.includes(course.course_id))
                }
                else {
                    return res.data;
                }
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getCourseById(courseId) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl + `/get_course/${courseId}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load course", "fail")
            return [];
        }
    };

    async addNewCourse(courseDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiCourseUrl + '/add_new_course', { token: userInfoToken }, true, courseDetails);
            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async updateEnrollUsersToCourse(courseId, userEnrollDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiCourseUrl + `/enroll_user_to_course/${courseId}`, { token: userInfo?.token }, true, userEnrollDetails);
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async updateEnrollUsersToPastPaper(pastPaperId, userEnrollDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiTopicalPastPapersUrl + `/enroll_user_to_past_paper/${pastPaperId}`, { token: userInfo?.token }, true, userEnrollDetails);
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async updateCourse(courseId, courseDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiCourseUrl + `/update_course/${courseId}`, { token: userInfo?.token }, true, courseDetails);
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateUserMarksWithAttendence(withAttendenceData) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl + `/update_multiple_user_exam_details`, { token: userInfo?.token }, true, withAttendenceData);
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateUserMarksWithOutAttendence(withoutAttendenceData) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + '/add_multiple_user_exam_details', { token: userInfoToken }, true, withoutAttendenceData);
            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async updateUserAttendenceWithAttendenceid(withAttendenceData) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl + `/update_multiple_student_attendance_details`, { token: userInfo?.token }, true, withAttendenceData);
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateUserAttendencesWithOutAttendenceid(withoutAttendenceData) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + '/add_multiple_student_attendance_details', { token: userInfoToken }, true, withoutAttendenceData);
            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getTropicalPastPapersByType(courseType) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalPastPapersUrl + `/get_past_paper_by_type/${courseType}`);
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getEnrolledPastPapersById(paperIds) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiTopicalPastPapersUrl + '/get_past_papers_by_ids', { token: userInfoToken }, true, { "past_papers_ids": paperIds });
            if (res?.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getEnrolledCourseById(courseIds) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiCourseUrl + '/get_courses_by_ids', { token: userInfoToken }, true, { "course_ids": courseIds });
            if (res?.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getCourseBySlug(courseSlug) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl + `/get_course_by_slug/${courseSlug}`,);
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async getLectureByCourseId(courseId) {
        try {

            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl + `/get_lessons_by_course/${courseId}`,{},true,{},'application/json',true);
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getOnlineClasses() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiOnlineClassesUrl + `/get_all_online_classes_details`, { token: userInfoToken });
            if (res?.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getExamDetails() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiExamsUrl + `/get_all_exam_details`, { token: userInfoToken });
            if (res?.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    
    async getAttendenceDetails() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfoToken.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_student_attendance_details/${userInfoToken?.user_id}`, { token: userInfoToken?.token });
            if (res?.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
  
    async getVideoDetails() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiVideoUrl + `/fetch_lms_videos_from_aws`, { token: userInfoToken }); 
            if (res?.status === 200) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async sendQuestionForm(formData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiContactUrl + '/user_questions', {}, true, formData);
            if (res?.status === 201) {
                return res;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async sendLeadQuestionForm(formData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiContactUrl + '/user_lead_questions', {}, true, formData);
            if (res?.status === 201) {
                return res;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async paymentform(formData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiContactUrl + '/payment_proceed', {}, true, formData);

            if (res?.status === 201) {
                return res;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    

    // Day api
    async getDayById(dayId) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_enum_day/${dayId}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load Exam", "fail")
            return [];
        }
    };
    async addNewDay(dayDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + '/add_enum_day', { token: userInfoToken }, true, dayDetails);
            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateDay(dayId, dayDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl + `/update_enum_day/${dayId}`, { token: userInfo?.token }, true, dayDetails);
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    // Exam api
    async getExamById(examId) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiExamsUrl + `/get_exam_detail/${examId}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load Exam", "fail")
            return [];
        }
    };
    async addNewExam(examDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiExamsUrl + '/add_new_exam_detail', { token: userInfoToken }, true, examDetails);
            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateExam(examId, examDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiExamsUrl + `/update_exam_detail/${examId}`, { token: userInfo?.token }, true, examDetails);
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    // Notes api
    async getNotesById(notesid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalNotesUrl + `/get_topical_notes/${notesid}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load Notes", "fail")
            return [];
        }
    };
    async addNewNotes(notesDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiTopicalNotesUrl + '/add_new_topical_notes', { token: userInfoToken }, true, notesDetails);

            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateNotes(notesid, notesDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiTopicalNotesUrl + `/update_topical_note/${notesid}`, { token: userInfo?.token }, true, notesDetails);

            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    // campus api
    async getCampusById(campusId) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiCampusUrl + `/get_campus/${campusId}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load Campus data", "fail")
            return [];
        }
    };
    async addNewCampus(campusDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiCampusUrl + '/add_campus', { token: userInfoToken }, true, campusDetails);

            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateCampus(campusId, campusDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiCampusUrl + `/update_campus/${campusId}`, { token: userInfo?.token }, true, campusDetails);

            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    // Online Class api
    async getClassById(classid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiOnlineClassesUrl + `/get_online_class_detail/${classid}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load Online Class", "fail")
            return [];
        }
    };
    async addNewClass(classDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiOnlineClassesUrl + '/add_new_online_class_detail', { token: userInfoToken }, true, classDetails);

            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateClass(classid, classDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiOnlineClassesUrl + `/update_online_class_detail/${classid}`, { token: userInfo?.token }, true, classDetails);

            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    // Topical Past Paper api /get_past_paper
    async getPaperById(paperid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalPastPapersUrl + `/get_past_paper/${paperid}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load past paper", "fail")
            return [];
        }
    };
    async addNewPaper(paperDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiTopicalPastPapersUrl + '/add_new_past_paper', { token: userInfoToken }, true, paperDetails);

            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updatePaper(paperid, paperDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiTopicalPastPapersUrl + `/update_past_paper/${paperid}`, { token: userInfo?.token }, true, paperDetails);

            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    // User Controls Admin
    async getUserById(userid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/${userid}`, { token: userInfoToken?.token, user_type_id: userInfoToken?.user_id }, true);

            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            showAlertMessage("could not load past paper", "fail")
            return [];
        }
    };
    async updateUser(userid, userDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl + `/update_user_profile/${userid}`, { token: userInfo?.token }, true, userDetails);

            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getExamAttendanceDetails(userId = null) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfoToken?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_user_exam_details/${!userId ? userInfoToken?.user_id : userId}`, { token: userInfoToken?.token });
            if (res?.status === 200) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async getUserExamAttendanceDetailsById(examination_attendance_id) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/get_user_exam/${examination_attendance_id}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async getUsersByCategoryAndCampus(student_category, student_campus_id) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails();
            }

            const res = await SystemApiCallSettings(
                'GET',
                `${Settings.apiUserUrl}/get_users_by_category_campus?student_category=${student_category}&student_campus_id=${student_campus_id}`,
                { token: userInfoToken },
                true
            );
            if (res?.status === 200) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Error in API call: ", error);
            return {};
        }
    }


    async addNewUserExamAttendanceDetails(payload) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + `/add_new_user_exam_details`, { token: userInfoToken }, false, payload);
            if (res?.status === 201) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async updateUserExamAttendanceDetails(examination_attendance_id, apiPayload) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl + `/update_user_exam_details/${examination_attendance_id}`, { token: userInfo?.token }, false, apiPayload);

            if (res?.status === 202) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async getVideoToken(key) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfoToken) {
                fetchUserDetails()
                showAlertMessage("Please login to continue", "fail")
                return
            }
            const res = await SystemApiCallSettings('GET', Settings.apiVideoUrl + `/get_video_token?key=${key}`, { token: userInfoToken?.token }, true);

            if (res?.status === 200) {
                return res.data;
            } else {
                showAlertMessage(res?.message, "fail")
                return "";
            }
        } catch (error) {
            showAlertMessage("could not load past paper", "fail")
            return [];
        }
    };



    // async getUsersByCategoryAndCampus(student_category, student_campus_id) {
    //     try {
    //         const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
    //         if (!userInfoToken) {
    //             await fetchUserDetails();
    //         }
    //         const apiUrl = `${Settings.apiUserUrl}/get_user_exam/get_users_by_category_campus?student_category=${student_category}&student_campus_id=${student_campus_id}`;

    //         // Make the API call
    //         const res = await SystemApiCallSettings('GET', apiUrl, { token: userInfoToken }, true);

    //         // Check the response status and return the data if successful
    //         if (res?.status === 200) {
    //             return res.data;  // Return only the data part
    //         } else {
    //             return {};  // Return an empty object if the status isn't 200
    //         }
    //     } catch (error) {
    //         console.error("Error fetching user exam attendance details:", error);  // Log the error for debugging
    //         return {};  // Return an empty object on error
    //     }
    // };

    // User api
    async registerUserToDB(userData ,contentType='application/json') {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl + '/register-new-user', {}, true, userData,contentType);
            if (res.status === 201) {
                return res;
            } else if (res?.status === 429) {
                showAlertMessage(res?.message, "fail")
                return [];
            }
            else {
                showAlertMessage(res?.message, "fail")
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async updateUserProfile(userData) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl + `/update_user_profile/${userInfo?.user_id}`, { token: userInfo?.token }, true, userData , "multipart/form-data");
            if (res?.status === 202) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };
    async addUserProfileImg(userData,userId,userToken) {
        try {
            
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl + `/update_user_profile_image/${userId}`, { token:userToken }, true, userData , "multipart/form-data");
            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getUserTokenStatus() {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/${userInfo?.user_id}`, { token: userInfo?.token }, true, {}, 'application/json', true);
            if (res?.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return [];
        }
    };

    async getUserDetails() {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl + `/${userInfo?.user_id}`, { token: userInfo?.token, user_type_id: userInfo?.user_id }, true, {}, 'application/json', true);
            if (res?.status === 200) {
                return { response: res, token: userInfo?.token };
            }
            else if (res?.status == 404) {
                showAlertMessage(res?.message, "fail")
                fetchUserDetails()
            }
            else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    async getNotesByType(courseType) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalNotesUrl + `/get_topical_notes_by_type/${courseType}`);
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    /* Api Calls for Course Lessons */

    async getCourseLessonById(lesson_id) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl + `/get_lesson_by_id/${lesson_id}`, { token: userInfoToken }, true);
            if (res?.status === 200) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async addNewCourseLesson(payload) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiCourseUrl + `/add_new_course_lesson`, { token: userInfoToken }, false, payload);
            if (res?.status === 201) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

    async updateCourseLesson(lesson_id, apiPayload) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('PUT', Settings.apiCourseUrl + `/update_course_lesson/${lesson_id}`, { token: userInfo?.token }, false, apiPayload);
            
            if (res?.status === 202) {
                return res;
            } else {
                return {};
            }
        } catch (error) {
            return {};
        }
    };

  
    /* POST API Calls */
    async addNewFileToAWS(awsPayload, contentType) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if (!userInfoToken) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('POST', Settings.apiVideoUrl + '/upload_lms_videos', { token: userInfoToken }, true, awsPayload, contentType);

            if (res?.status === 201) {
                return res;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    }; 

    /* Delete API Calls */
    async removeSystemNote(note_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiTopicalNotesUrl + '/delete_topical_notes/' + note_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };

    async removeSystemOnlineClass(class_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiOnlineClassesUrl + '/delete_online_classes_details/' + class_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };
    async removeSystemCampus(campus_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiCampusUrl + '/delete_campuses/' + campus_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };

    async removeSystemTopicalPastPaper(past_paper_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiTopicalPastPapersUrl + '/delete_past_papers/' + past_paper_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };

    async removeSystemExams(exam_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiExamsUrl + '/delete_exam_details/' + exam_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };
    async removeSystemDay(day_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiUserUrl + '/delete_enum_days/' + day_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };

    async removeSystemCourses(course_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiCourseUrl + '/delete_courses/' + course_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };

    async removeSystemCourseLessons(lesson_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiCourseUrl + '/delete_course_lessons/' + lesson_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };

    async removeSystemExamAttendance(examination_attendance_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiUserUrl + '/delete_user_exam_details/' + examination_attendance_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };

    async removeSystemUser(user_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (!userInfo?.token) {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('DELETE', Settings.apiUserUrl + '/delete_user_profile/' + user_id, { token: userInfo?.token });
            return res;
        } catch (error) {
            return {};
        }
    };
};