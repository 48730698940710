import React, { useState, useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import Settings from '../../CommonAPICalls/Settings';
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';
import html2pdf from 'html2pdf.js';
import Loader from '../../UI Helpers/Simple Loading/Loader';

const UserAttendenceChart = ({dashboardDisplay=false}) => {
    const yearOptions = Settings.yearOption;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const [selectedYear, setSelectedYear] = useState(yearOptions[yearOptions.length - 1]?.value || 1);
    const [selectedMonth, setSelectedMonth] = useState(1);
    const [chartData, setChartData] = useState(null);
    const [attendanceData, setAttendanceData] = useState([]);
    const [systemDays, setSystemDays] = useState([]);
    const [loading,setLoading] = useState(true)
    const chartRef = useRef()
    const reportRef = useRef();
    const spacerRef = useRef();
    const [chartImage, setChartImage] = useState(null); 
    const studentData= useSelector((state)=>state.userLogin).userInfo

    const apiCalls = new CommonCalls();

    useEffect(() => {
        const fetchAttendanceDetails = async () => {
            setLoading(true)
            try {
                const attendanceResponse = await apiCalls.getAttendenceDetails();
                const daysResponse = await apiCalls.getSystemDays();

                if (attendanceResponse && daysResponse) {
                    setAttendanceData(attendanceResponse.data || []);
                    setSystemDays(daysResponse.data || []);
                    
                    const currentYearOption = yearOptions.find(option => option.label === currentYear.toString());
                    if (currentYearOption) {
                        setSelectedYear(currentYearOption.value);
                    }

                    const monthsWithData = attendanceResponse.data
                        .filter(data => data.year === currentYearOption.value)
                        .map(data => data.month);
                    
                    if (monthsWithData.includes(currentMonth)) {
                        setSelectedMonth(currentMonth);
                    } else if (monthsWithData.length > 0) {
                        setSelectedMonth(Math.max(...monthsWithData));
                    }
                }
                setLoading(false)
            } catch (error) {
                setAttendanceData([]);
                setSystemDays([]);
                setLoading(false)
            }
        };
        fetchAttendanceDetails();
    }, []);

    useEffect(() => {
        const updateChartData = () => {
            const filteredData = attendanceData.filter(
                (data) => data.year === selectedYear && data.month === selectedMonth
            );

            if (filteredData.length > 0) {
                const { total_attendence, student_attendence } = filteredData[0];
                const matchedDay = systemDays.find((item) => item.enum_id === total_attendence);
                
                const totalDays = matchedDay ? parseInt(matchedDay.enum_name.replace(/Days?/i, '').trim(), 10) || 0 : 0;
                
                setChartData({
                    labels: ['Total Attendance', 'Student Attendance'],
                    datasets: [
                        {
                            data: [totalDays, student_attendence],
                            backgroundColor: ['#FF6384', '#36A2EB'],
                        },
                    ],
                });
            } else {
                setChartData(null);
            }
        };

        updateChartData();
    }, [selectedYear, selectedMonth, attendanceData, systemDays]);
    
const captureChartImage = async () => {
    if (chartRef.current) {
        const canvas = await html2canvas(chartRef.current, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        setChartImage(()=>imgData); 
    }
};

const handleDownloadPDF = async () => {
    await captureChartImage();
    const element = reportRef.current;
    const spacer = spacerRef.current;
    spacer.style.display = 'block';
    element.style.display = 'block';

    const options = {
        filename: `${studentData.full_name}_Attendence_Report.pdf`,
        jsPDF: { unit: 'pt', format: 'a4' },
        html2canvas: { scale: 2 }
    };

    setTimeout(() => {
        html2pdf().set(options).from(element).save().then(() => {
            element.style.display = 'none';
            spacer.style.display = 'none';
        });
    }, 200);
};

const getAttendenceColor = (attendence,total) => {
    if (attendence === undefined || attendence === null) return 'black'; 

    const percentage = (attendence / total) * 100;

    if (percentage >= 80) {
        return "green";
    } else if (percentage >= 60) {
        return "yellowgreen"; 
    } else if (percentage >= 40) {
        return "yellow"; 
    } else if (percentage >= 20) {
        return "orange"; 
    } else {
        return "red"; 
    }
};

    return (
        <div className='chart-container'>
             {loading ? (
                <div className='mt-5'> <Loader /> </div>
            ) :(<>
            {
            !dashboardDisplay && 
            <div className='d-flex flex-lg-row flex-column align-items-center justify-content-between gap-3 py-3 px-3'>
            <div className='d-flex flex-lg-row flex-column align-items-center justify-content-start gap-3'>
            <div className='page-size'>
                <label>
                    Select Month:
                    <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(Number(e.target.value))}
                    >
                        {Settings.monthsOption.map((month) => (
                            <option key={month.label} value={month.value}>
                                {month.label}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <div className='page-size'>
                <label>
                    Select Year:
                    <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                    >
                        {yearOptions.map((year) => (
                            <option key={year.value} value={year.value}>
                                {year.label}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
          
            </div>
            {chartData &&
                <button
                    type="button"
                    className="connect-button p-3 mt-0"
                    onClick={handleDownloadPDF}
                >
                    <div>
                    <span> Generate Report </span>
                    <img src='/images/icons/report-icon.png' alt='report icon' />
                    </div>
                </button> 
            }
            </div>
            }
            {chartData ? (
                <>
                <div ref={chartRef} className={`w-50 d-flex align-items-center justify-content-center mx-auto ${dashboardDisplay?"dashboardDisplay-container":""}`}>
                    <Doughnut data={chartData}/>
                </div>
                   {/* Hidden report section for PDF generation */}
                   <div ref={spacerRef} style={{height:"500vh",display:'none'}}></div>
                        <div ref={reportRef} style={{ display: 'none', padding: '20px',marginTop:"20px" }}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between", gap:"20px",marginBottom:"50px"}}>
                                <img src='/images/mk-logo-white-landscape.png' alt='logo'/>
                                <h2 style={{ color: '#EE202D' }}>Monthly <span style={{color:"#1F2F54"}}>Attendance Report</span></h2>
                            </div>

                            <div style={{display:"flex",alignItems:"start", gap:"20px",marginBottom:"20px"}}>
                                <div style={{marginBottom: '10px',border:"1px solid #B5B5B5",borderRadius:"5px",paddingBottom:"10px",width:"100%"} }>
                                    <div style={{ backgroundColor: '#1D1F5F', color: '#FFFFFF', padding: '10px',borderRadius:"5px",marginBottom:"20px",textAlign:"center" }}>
                                        <h5 style={{fontWeight:"bold"}}>Student Details</h5>
                                    </div>
                                        <p style={{paddingLeft: '15px'}}>Name: {studentData?.full_name}</p>
                                        <p style={{paddingLeft: '15px'}}>Phone: {studentData?.phone_number}</p>
                                        <p style={{paddingLeft: '15px'}}>Email: {studentData?.email}</p>
                                </div>

                                <div style={{marginBottom: '10px',border:"1px solid #B5B5B5",borderRadius:"5px",paddingBottom:"10px",width:"100%"} }>
                                    <div style={{ backgroundColor: '#1D1F5F', color: '#FFFFFF', padding: '10px',borderRadius:"5px",marginBottom:"20px",textAlign:"center" }}>
                                        <h5 style={{fontWeight:"bold"}}>Attendance Details</h5>
                                    </div>
                                        <div style={{ marginBottom: '8px' }}>
                                            <p style={{paddingLeft: '15px'}}>Month: {(Settings.monthsOption.find((month) => month.value == selectedMonth)).label}</p>
                                            <p style={{paddingLeft: '15px'}}>Year: {(yearOptions.find((year) => year.value == selectedYear)).label}</p>
                                        </div>
                                </div>
                            </div>

                            <div style={{display:"flex",alignItems:"center",justifyContent:"space-evenly", gap:"20px",}}>
                            {chartImage && (
                                <div style={{ textAlign: 'center', marginTop: '20px',width:"50%" }}>
                                    <img src={chartImage} alt="Chart" style={{ width: '100%' }} />
                                </div>
                            )}

                            {
                                <div style={{width:"35%"}}>
                                   <div style={{backgroundColor:"#EBEFF3",borderRadius:"10px",padding:"10px",borderBottom:"5px solid #3CA3E9"}}>
                                            <div style={{display:"flex",alignItems:'center',justifyContent:"space-evenly",gap:"10px"}}>
                                            <div style={{textAlign:"center"}}>
                                                <p>Total Days</p>
                                                <p style={{fontWeight:"900",fontSize:"30px",color:"#1D1F5F"}}>{chartData?.datasets[0]?.data[0]}</p>
                                            </div>
                                            <div style={{textAlign:"center"}}>
                                                <p>Your Attendance</p>
                                                <p
                                                    style={{
                                                        fontWeight: "900",
                                                        fontSize: "30px",
                                                        color: getAttendenceColor(
                                                            chartData?.datasets[0]?.data[1],chartData?.datasets[0]?.data[0]
                                                        )
                                                    }}
                                                >
                                                    {chartData?.datasets[0]?.data[1]}
                                                </p>
                                            </div>

                                            </div>
                                        </div>
                                </div>
                            }
                            
                            </div>
                        </div>
                </>
            ) :  (
                <div className='d-flex align-items-center justify-content-center' style={{height:`${!dashboardDisplay?"400px":"150px"}` ,textAlign:"center",width:"100%"}}>
                <p>No data available for the selected year and month.</p>
                </div>
            )}
            </>)}
        </div>
    );
};

export default UserAttendenceChart;
