import React, { useState } from 'react';
import './OLevelNotes.css';
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';
import { useSelector } from 'react-redux';
import Modal from '../Model/Modal';
import LoginSignup from '../LoginSignup/LoginSignup';
import Signup from '../SignupComponent/Signup';

const getTextColor = (() => {
  const randomColors = [
    { color: 'pink', buttonColor: '#E17AA1' },
    { color: 'green', buttonColor: '#0EC2A8' },
    { color: 'yellow', buttonColor: '#FCCD37' },
    { color: 'orange', buttonColor: '#F66C01' },
    { color: 'lightblue', buttonColor: '#A8B5EB' }
  ];
  let index = 0;
  
  return () => randomColors[index++ % randomColors.length];
})();

const OLevelNotes = ({notesData}) => {
  const cards = [
    { color: 'lightblue', buttonColor: 'rgb(168, 181, 235)', img: '/images/Group-1' },
    { color: 'pink', buttonColor: '#E17AA1' },
    { color: 'green', buttonColor: '#0EC2A8' },
    { color: 'yellow', buttonColor: '#FCCD37' },
    { color: 'orange', buttonColor: '#F66C01' },
    { color: 'lightblue', buttonColor: '#A8B5EB' },
    { color: 'pink', buttonColor: '#E17AA1' },
    { color: 'green', buttonColor: '#0EC2A8' },
    { color: 'yellow', buttonColor: '#FCCD37' },
    { color: 'orange', buttonColor: '#F66C01' },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signUpform, setSignUpform] = useState(true);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {setIsModalOpen(false)};
  const openSignUp = ()=>{
    setSignUpform(true)
    setIsModalOpen(true)
  }
  const openLogin = ()=>{
    setSignUpform(false)
    setIsModalOpen(true)
  }
  const userLoginStatus = useSelector((state)=>state.userLogin)

  return (
    <div className="container">
      <div className="cards-container">
        {notesData?.map((card, index) => (
          <div className="card d-flex flex-column justify-content-between" key={index}>
            <div className="icon" style={{ backgroundColor: getTextColor().color }}>
            <img src="/images/Group-1996.svg" alt="Description" />
            </div>
            <h3 className="title">{card?.notes_title}</h3>
            <button className="btnCard" 
              style={{ backgroundColor: getTextColor().buttonColor }}
              onClick={()=>{
                if(userLoginStatus.userInfo?.token)
                  {
                    window.open(card?.notes_drive_link)
                  }
                  else{
                    setIsModalOpen(true)
                    showAlertMessage("Please login to access FREE Notes","fail")
                  }
              }}
            >Open Drive Link</button>
          </div>
        ))}
        <Modal status={isModalOpen} closeModal={closeModal}>
           {!signUpform ? <LoginSignup closeModal={closeModal} openSignUp={openSignUp}/> : <Signup closeModal={closeModal} openLogin={openLogin}/>}{" "}
         </Modal>
      </div>
    </div>
  );
};

export default OLevelNotes;
