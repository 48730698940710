import CryptoJS from "crypto-js";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";

export const encryptPassword = (password) => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
  const derived_key = CryptoJS.enc.Base64.parse(secretKey);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);
  const encryptionOptions = {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  };

  const encryptedPassword = CryptoJS.AES.encrypt(
    password,
    derived_key,
    encryptionOptions
  ).toString();

  const base64EncryptedPassword = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encryptedPassword)
  );

  return base64EncryptedPassword;
};


export const decryptPassword = (encryptedPassword) => {
  try {
    const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    const derived_key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);
    
    const decryptionOptions = {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    };

    const decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, derived_key, decryptionOptions);
    const decryptedPassword = decryptedBytes.toString(CryptoJS.enc.Utf8);
    
    return decryptedPassword;
  } catch (error) {
    return null;
  }
};

export const validateForm = (inputsFieldAr, formInputData) => {
  let passStatus = true;
  inputsFieldAr.map((input) => {
    if (!formInputData[input.name] && passStatus && input.required) {
      showAlertMessage(`${input.label} field required`, "fail");
      passStatus = false;
    }
  });

  return passStatus;
};
