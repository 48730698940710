import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter} from "../../MasterComponents/CommonFunctionalities"; 
import { showAlertMessage, checkIfArrayIsEmpty } from '../../MasterComponents/CommonFunctionalities';
import { getArrayItemFromLocalStorage } from "../../UI Helpers/TabRecorder/TabRecorderFunctions";

const SystemCampusesListings = ({ studentPortal = false }) => {
  const [campusData, setCampusData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(getArrayItemFromLocalStorage(9));
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => { getAllSystemCampuses(); }, []);

  const getAllSystemCampuses = async () => { 
    try {
      const apiResponse = await apiCalls.getSystemCampuses();
      if (apiResponse) {
        setCampusData(apiResponse?.data);
        setTotalRecords(apiResponse?.data?.length);
        setLoading(false);
      }
    } catch (err) {
      setCampusData([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onTopicClicked = (row, colKey) => {
    if(colKey === 'campus_name'){
      navigate(`/portal/system-campuses?campusid=${row?.campus_id}`);
    }
    
  };

  const onDeleteSystemOnlineClasses = async (rowItems) => {
    if(checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoading(true);

    for (const item of rowItems) {   
      try {
        const apiResponse = await apiCalls.removeSystemCampus(item?.campus_id);
        if(apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getAllSystemCampuses();
  };
  
  const gridColumnHeaders = [
    { field: 'campus_name', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Campus Name' },
  ];

  return (
    <div className='listings_view_wrapper'> 
      <h1 className="h1_main">Campuses</h1>

      <CustomDataGrid 
        columns={gridColumnHeaders} 
        data={campusData} 
        pageSize={pageSize} 
        loadingState={loading}
        hideAllBtns={false}
        checkboxRequired={true}
        pagination={false}
        onAddClick={() => navigate('/portal/system-campuses')}
        onCellClicked={onTopicClicked}
        onSelectedRowsDelete={onDeleteSystemOnlineClasses}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
        tabID={9}
      />
    </div>
  )
}

export default SystemCampusesListings
