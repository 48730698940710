
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { customIconInGridCell,getDateFormatted } from "../../MasterComponents/CommonFunctionalities";

const OnlineClassListing = ({ studentPortal = true }) => {
  const [onlineClassesData, setOnlineClassesData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => { getAllSystemOnlineClasses(); }, []);

  const getAllSystemOnlineClasses = async () => { 
    try {
      const apiResponse = await apiCalls.getUserOnlineClasses();
      
      if (apiResponse) {
        setOnlineClassesData(apiResponse?.data);
        setTotalRecords(apiResponse?.data?.length);
        setLoading(false);
      }
    } catch (err) {
      setOnlineClassesData([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

 
  
  const gridColumnHeaders = [
    { field: 'class_topic',columnName: 'Class Topic' },
    { field: 'class_subject', columnName: 'Class Subject' },
    { field: 'session_date_time',cellRenderer: getDateFormatted, customRendererLink: true,  columnName: 'Session Date Time' },
    { field: 'zoom_live_link', columnName: 'Zoom Link',cellRenderer:customIconInGridCell,customRendererLink: true }
  ];

  return (
    <div className='listings_view_wrapper'> 
      <h1 className="h1_main">{studentPortal ? 'Online Classes' : 'All System Online Classes'}</h1>

      <CustomDataGrid 
        columns={gridColumnHeaders} 
        data={onlineClassesData} 
        pageSize={pageSize} 
        loadingState={loading}
        hideAllBtns={true}
        checkboxRequired={!studentPortal}
        pagination={false}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
      />
    </div>
  )
}

export default OnlineClassListing
