import React, { useEffect, useRef, useState } from 'react';
import { showAlertMessage } from '../components/MasterComponents/CommonFunctionalities';
import Modal from '../components/Model/Modal';
import { decryptPassword, encryptPassword } from '../components/UI Helpers/HelperFunctions/Helper';
import CommonCalls from '../components/CommonAPICalls/CommonCalls';
import Loader from '../components/UI Helpers/Simple Loading/Loader';
const apiCall = new CommonCalls();

function VideoPlayer({toogleState, setToogleState}) {
  const videoRef = useRef();
  const [errorMessage, setErrorMessage] = useState(false);
  const [accesToken, setAccessToken] = useState("");
  const [originalSrc, setOriginalSrc] = useState("");

  const closeModalFn = () => setToogleState('');

  var currentVideo = toogleState.split('/');
  currentVideo = encryptPassword(currentVideo[currentVideo.length - 1].replace(/ /g, '%20'));

  const getVideoToken = async () => {
    const response = await apiCall.getVideoToken(currentVideo);
    if (response) {
      const decryptedToken = decryptPassword(response);
      setAccessToken(decryptedToken);
      const videoSrc = `/api/videos/singlevideo/?token=${decryptedToken}`;
      setOriginalSrc(videoSrc);
    }
  };

  useEffect(() => {
    getVideoToken();
  }, [toogleState]);

  useEffect(() => {
    if (videoRef.current && originalSrc) {
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
            const currentSrc = videoRef.current.getAttribute('src');
            if (currentSrc !== originalSrc) {
              videoRef.current.setAttribute('src', originalSrc);
            }
          }
        }
      });

      observer.observe(videoRef.current, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [originalSrc]);

  return (
    <>
      {!accesToken ? (
        <Loader />
      ) : (
        <div>
          <Modal status={toogleState} closeModal={closeModalFn}>
            <div className="container">
              {errorMessage ? (
                <></>
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="w-100 py-5 d-flex justify-content-end">
                    <img
                      src="/icons/login-cross-icon.png"
                      alt="cross-icon"
                      className="login-cross-icon img-fluid"
                      style={{ cursor: 'pointer' }}
                      onClick={closeModalFn}
                    />
                  </div>
                  <video
                    muted
                    autoPlay
                    controls
                    src={originalSrc}
                    style={{ width: '80vw' }}
                    ref={videoRef}
                    controlsList="nodownload"
                  ></video>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default VideoPlayer;
