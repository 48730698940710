import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { validateForm } from "../../UI Helpers/HelperFunctions/Helper";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { checkIfArrayIsEmpty, gridLinkValueFormatter, showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import Settings from "../../CommonAPICalls/Settings";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import DetailViewLessonPopup from "./DetailViewLessonPopup";

const apiCall = new CommonCalls();
 
const DetailViewSystemCourses = () => {
  const courseLevelRef = useRef(null);
  const [saveBtn, setSaveBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingLessons, setLoadingLessons] = useState(true);

  const [popupDetails, setPopupDetails] = useState({});
  const [formInputData, setFormInputData] = useState({});
  const [courseLessons, setCourseLessons] = useState([]); 

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [stdCategory, setStdCategory] = useState(-1);
  const [campusesList, setCampusesList] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const courseId = params.get("courseid");
  const navigate = useNavigate()

  useEffect(() => { 
    if (courseId) {
      setLoading(true);
      getCourseDetails();
      getCourseLessonDetails();
    } else {
      setLoading(false);
      setLoadingLessons(false);
    }
  }, [courseId]);
  
  useEffect(()=>{
    setStdCategory(formInputData?.course_type_id)
    
  },[formInputData.course_type_id])
  
  useEffect(()=>{
    getUserCampusOptions()
  },[])

  const getCourseDetails = async () => {
    const response = await apiCall.getCourseById(courseId);
    if (response) {
      setFormInputData({
        course_name: response?.course_name,
        course_type_id: response?.course_type_id,
        slug_url: response?.slug_url,
        course_level: response?.course_level,
        course_description: response?.course_description,
        free_view: response?.free_view,
        course_price: response?.course_price,
        enrolled_users: response?.enrolled_users,
        student_campus_id: response?.student_campus_id,
      });
      setLoading(false);
    }
  };

  const getCourseLessonDetails = async () => { 
    try {
      const apiResponse = await apiCall.getSystemCourseLessons(courseId); 
      setCourseLessons(apiResponse?.data || []);
      setTotalRecords(apiResponse?.data?.length || 0);
      setLoadingLessons(false);
    } catch (err) {
      setCourseLessons([]);
      setTotalRecords(0);
      setLoadingLessons(false);
    }
  };

  const getUserCampusOptions = async () => {
    const apiResponse = await apiCall.getSystemCampuses();
    if (apiResponse) {
      setCampusesList(apiResponse?.data?.map((item) => {
        return {
          value: item?.campus_id,
          label: item?.campus_name
        }
      }));
    }
  };
  
  const onDeleteSystemCourseLessons = async (rowItems) => {
    if(checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoadingLessons(true);

    for (const item of rowItems) {   
      try {
        const apiResponse = await apiCall.removeSystemCourseLessons(item?.lesson_id);
        if(apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getCourseLessonDetails();
  };

  const CourseLessonsModule = () => {
    const gridColumnHeaders = [
      { field: 'lesson_title', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Lesson Title' },
      { field: 'lesson_description', columnName: 'Lesson Description' },
      { field: 'lesson_duration', columnName: 'Lesson Duration (minutes)' },
      { field: 'lesson_link', columnName: 'Lesson Link' }
    ];

    const onLessonTitleClicked = (row, colKey) => {
      if(colKey === 'lesson_title'){ 
        setPopupDetails({
          lesson_id: row?.lesson_id,
          openPopup: true
        });
      };
    };

    return (
      <CustomDataGrid
        columns={gridColumnHeaders} 
        data={courseLessons} 
        pageSize={pageSize} 
        loadingState={loadingLessons}
        pagination={false}
        onAddClick={() => setPopupDetails({ lesson_id: null, openPopup: true }) }
        onCellClicked={onLessonTitleClicked}
        onSelectedRowsDelete={onDeleteSystemCourseLessons}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
      />
    )
  };

  const addCourseData = async () => { 
    setSaveBtn(true);
    let validationResponse = validateForm(inputsFieldAr, formInputData);
    if (!validationResponse) {
      setSaveBtn(false);
      return;
    }
    let apiFunction = async () => {
      return await apiCall.addNewCourse(formInputData);
    };
    if (courseId) {
      apiFunction = async () => {
        return await apiCall.updateCourse(courseId, formInputData);
      };
    }
    const response = await apiFunction();
    if (response?.status === 201 || 202) {
      setSaveBtn(false);
      if (response?.status === 201)
      {
        if(formInputData?.enrolled_users && response?.course_id) {
          await apiCall.updateEnrollUsersToCourse(response?.course_id, {
            user_ids: formInputData?.enrolled_users
          }) 
        }
        navigate("/portal?tab_id=1")
      } 
      if(formInputData?.enrolled_users && courseId) {
        await apiCall.updateEnrollUsersToCourse(courseId, {
          user_ids: formInputData?.enrolled_users
        }) 
      }
      showAlertMessage(response?.message, "success");
      return response;
    } else {
      setSaveBtn(false);
      showAlertMessage(response?.message, "fail");
      return;
    }
  };
  const inputsFieldAr = [
    {
      label: "Course Name",
      className: "col-lg-4",
      inputType: "text",
      name: "course_name",
      id: "course_name",
      required: true,
    }, 
    {
      label: "Course Description",
      className: "col-lg-4",
      inputType: "text",
      name: "course_description",
      id: "course_description",
      required: true,
    },
    {
      label: "Course Price (PKR)",
      className: "col-lg-4",
      inputType: "number",
      name: "course_price",
      id: "course_price"
    },
    {
      label: "FREE Course",
      className: "col-lg-4 d-flex align-items-center justify-content-center",
      inputType: "checkbox",
      name: "free_view",
      id: "free_view",
    },
    {
      label: "Slug URL",
      className: "col-lg-4",
      inputType: "text",
      name: "slug_url",
      id: "slug_url",
      required: true,
    },
    {
      label: "Course Type",
      className: "col-lg-4",
      inputType: "dropdown",
      options: Settings.enumTypes,
      name: "course_type_id", 
      id: "course_type_id",
      required: true,
    },
    {
      label: "Course Level",
      className: "col-lg-4",
      inputType: "dropdown",
      options: Settings.enumCourseLevels,
      name: "course_level", 
      id: "course_level",
      required: true,
    },
    {
      label: "Student Campus",
      className: "col-lg-4",
      options: campusesList,
      inputType: "dropdown",
      name: "student_campus_id",
      id: "student_campus_id",
      required: false
    },
    {
      label: "Users (For Granting Course Access)",
      className: "col-lg-4",
      inputType: "multi_dropdown", 
      defaultValues: formInputData?.enrolled_users ?? [],
      name: "enrolled_users", 
      id: "enrolled_users",
      enableSearch:true,
      studentCataGoryApiData:stdCategory,
      required:true
    }
  ];

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="container-fluid pe-4 ps-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSaveBtn(true);
              addCourseData();
            }}
          >
            <div className="w-100 d-flex justify-content-between">
            <button
                type="button"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
                onClick={()=>{navigate("/portal?tab_id=1")}}
              >
                <div>
                  <span className="d-flex align-items-center"><img src="/images/icons/white-arrow-left.png" alt="arrow icon" style={{width:"25px",height:"25px"}}/> Back </span>{" "}
                </div>
              </button>
              <button
                type="submit"
                className="connect-button w-fit p-3"
                disabled={saveBtn}
              >
                <div>
                  <span> Save </span>{" "}
                  {saveBtn ? (
                    <output
                      className="spinner-border spinner-border-sm ms-1"
                      aria-hidden="true"
                    ></output>
                  ) : (
                    ""
                  )}
                </div>
              </button>
            </div>
            <FieldsRenderer
              fieldsArray={inputsFieldAr}
              updateState={setFormInputData}
              currentState={formInputData}
            />
          </form>
          { courseId && CourseLessonsModule() }
        </div>
      )}
      { popupDetails?.openPopup && <DetailViewLessonPopup reloadParentGrid={getCourseLessonDetails} course_id={courseId} openPopup={popupDetails?.openPopup} setPopupState={setPopupDetails} lesson_id={popupDetails?.lesson_id} freeCourse={formInputData?.free_view} /> }
    </>
  );
};

export default DetailViewSystemCourses;