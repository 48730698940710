let Settings = {
  apiCourseUrl: "/api/course",
  apiTopicalPastPapersUrl:"/api/topical_papers",
  apiExamsUrl: "/api/exam_sessions",
  apiOnlineClassesUrl: "/api/online_sessions",
  apiCampusUrl: "/api/campus",
  apiUserUrl: "/api/system_users",
  apiTopicalNotesUrl: '/api/topical_notes',
  apiContactUrl: '/api/contact', 
  apiAttendenceUrl: '/api/attendence', 
  apiVideoUrl: '/api/videos', 
  enumTypes: [ { value: 1, label: "O Level" }, { value: 2, label: "AS Level" }, { value: 3, label: "A2 Level" } ],
  enumCourseLevels: [ { value: 1, label: "Easy" }, { value: 2, label: "Intermediate" }, { value: 3, label: "Hard" } ],
  contactFormStudyLevelOptions:[ { value: 1, label: "O Level" }, { value: 2, label: "AS Level" }, { value: 3, label: "A2 Level" },{ value: 4, label: "IGCSE" } ],
  monthsOption:[{ value: 1, label: "January" },{ value: 2, label: "February" },{ value: 3, label: "March" },{ value: 4, label: "April" },{ value: 5, label: "May" },{ value: 6, label: "June" },{ value: 7, label: "July" },{ value: 8, label: "August" },{ value: 9, label: "September" },{ value: 10, label: "October" },{ value: 11, label: "November" },{ value: 12, label: "December" }],
  yearOption:[{ value: 1, label: "2024" },{ value: 2, label: "2025" },{ value: 3, label: "2026" },{ value: 4, label: "2027" },{ value: 5, label: "2028" },{ value: 6, label: "2029" },{ value: 7, label: "2030" }],
  daysOption:[{ value: 1, label: "15 Days" },{ value: 2, label: "20 Days" },{ value: 3, label: "30 Days" }]
};

export default Settings;